import { useState } from 'react';
import { Card, Stack, Typography, Menu, MenuItem, Avatar, Box } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import moment from 'moment';

export default function Message({ item }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).id);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      className="message"
      direction={user !== item?.adminData?._id ? 'row' : 'row-reverse'}
      mt={3}
      spacing={2}
      alignItems="start"
    >
      <Avatar>{item?.userData?.fullname?.charAt(0).toUpperCase()}</Avatar>
      <Box
        sx={{
          padding: '0px 5px 5px 5px',
          maxWidth: '70%',
          minWidth: '150px',
          ...(user !== item?.adminData?._id && { backgroundColor: '#CCDBFF', color: 'black' }),
          ...(user === item?.adminData?._id && { backgroundColor: '#dde7ff', color: 'black' }),
          borderRadius: '6px',
        }}
      >
        <Stack direction={'column'} spacing={-0.2}>
          <Stack direction={'column'} padding={0.5}>
            {user !== item?.adminData?._id && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '15px',
                  fontFamily: 'Sora',
                  lineHeight: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                {item?.userData?.fullname?.toUpperCase()}
              </Typography>
            )}
            <Typography
              padding={0.2}
              sx={{
                fontSize: '14px',
                fontFamily: 'Sora',
                fontWeight: '400',
                lineHeight: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {item.message}
            </Typography>
          </Stack>
          {user === item?.adminData?._id ? (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack direction="row" alignItems={'center'} justifyContent="space-between">
                <RemoveRedEyeOutlinedIcon
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  // onClick={handleClick}
                  style={{ fontSize: '12px', marginRight: '2px', color: 'gray', cursor: 'pointer' }}
                />
                <Typography variant="caption" sx={{ fontSize: '10px', fontFamily: 'Sora' }}>
                  {item?.views?.length ? item.views.length - 1 : 0}
                </Typography>
              </Stack>
              <Typography variant="caption" marginLeft={3} sx={{ fontSize: '10px', fontFamily: 'Sora' }}>
                {moment.utc(item.createdAt).format('DD-MMM-YY h:mm a')}
              </Typography>
            </Stack>
          ) : (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Typography variant="caption" marginLeft={3} sx={{ fontSize: '10px', fontFamily: 'Sora' }}>
                {moment.utc(item.createdAt).format('DD-MMM-YY h:mm a')}
              </Typography>
            </Stack>
          )}
        </Stack>
        {!!item?.views?.length && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Stack direction={'column'} alignItems="flex-start" sx={{ paddingX: '10px' }}>
              {item.views.map((item, key) => (
                <Typography key={key} variant="body2">
                  name{' '}
                </Typography>
              ))}
            </Stack>
          </Menu>
        )}
      </Box>
    </Stack>
  );
}
