import { Box, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import moment from 'moment';
import Iconify from './Iconify';

function TaskAttachments({ attachments, taskId }) {
  const navigate = useNavigate();
  const maxView = 8;
  const slicedAttachments = attachments?.slice(0, maxView);

  function mimeTypeToIcon(mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return 'vscode-icons:file-type-pdf2'; 
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'vscode-icons:file-type-word';
      case 'image/png' || 'image/jpeg' || 'image/jpg' || 'image/gif' || 'image/svg+xml' || 'image/webp':
        return 'flat-color-icons:picture';
      default:
        return 'flat-color-icons:picture';
    }
  }

  const bytesToKbOrMb = (bytes) => {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb > 1) {
      return `${Math.round(mb)} MB`;
    }
    return `${Math.round(kb)} KB`;
  }

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{
        borderRadius: '6px',
        padding: 3,
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography
              sx={{
                color: '#2e2e2e',
                fontFamily: 'Sora',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              {`Attachments (${attachments?.length})`}
            </Typography>
            <Typography
              sx={{
                color: '#0096db',
                fontFamily: 'Sora',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.11px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/dashboard/project/projects/task/attachments/${taskId}`)}
            >
              View All
            </Typography>
          </Stack>
        </Grid>
        {slicedAttachments?.map((attachment, index) => (
          <Grid item xs={12} key={index}>
            <Stack
              padding={2}
              sx={{
                border: '1px solid #B3B3B3',
                borderRadius: '6px',
                cursor: 'pointer',
                height: '100%',
                width: '100%',
              }}
              justifyContent={'center'}
              onClick={() => window.open(attachment?.fileURL, '_blank')}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '40px', height: '40px' }}>
                  <Iconify icon={mimeTypeToIcon(attachment?.mimeType)} width={40} height={40} />
                </Stack>
                <Stack direction={'column'} spacing={1} overflow={'hidden'} sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#0B132B',
                      fontFamily: 'Sora',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.11px',
                    }}
                  >
                    {attachment?.originalName.length > 40
                      ? `${attachment?.originalName.slice(0, 40)}...`
                      : attachment?.originalName}
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        color: '#4F4F4F',
                        fontFamily: 'Sora',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '-0.11px',
                      }}
                    >
                      {bytesToKbOrMb(attachment?.fileSize)}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#4F4F4F',
                        fontFamily: 'Sora',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '-0.11px',
                      }}
                    >
                      {moment.utc(attachment?.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default TaskAttachments;
