import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fShortenNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------

export default function AppWidgetSummaryNoIcon({ title, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      sx={{
        px: 3,
        py: 5,
        borderRadius: 2,
        justifyContent: "center",
        height: "100%",
        ...sx,
      }}
      {...other}
    >

      <Stack spacing={0.5} sx={{
        alignItems: "center",
      }}>
        <Typography variant="h4">{fShortenNumber(total) || 0}</Typography>

        <Typography variant="subtitle2" textAlign={"center"} sx={{ color: 'text.disabled' }}>
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}

AppWidgetSummaryNoIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};
