import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import BackIcon from '../assets/icons/back.png';

const commonTypographyStyles = {
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.11px',
};

const commonIconStyles = {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
};

function BreadCrumps({ project, category, task, singlePage, nonProject, mainPage, arrayItems, navigation = -1 }) {
  const navigate = useNavigate(-1);
  console.log("arrayItems", arrayItems)
  if (nonProject) {
    return (
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Box onClick={() => navigate(navigation)} component={'img'} src={BackIcon} sx={commonIconStyles} />
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/project/projects/${project.id}`)}>
          <Typography variant="headBreadCrumps" component={'p'} sx={{ color: '#2E2E2E' }}>
            {mainPage}
          </Typography>
        </Box>
        {arrayItems.map((item, index) => (
          <React.Fragment key={index}>
            <KeyboardArrowRight color="#0B132B" sx={{ fontSize: '32px' }} />
            <Typography
              variant="headBreadCrumps"
              component={'p'}
              sx={{ cursor: 'pointer', color: '#0B132B' }}
              onClick={() => navigate(item.path)}
            >
              {item.title}
            </Typography>
          </React.Fragment>
        ))}
      </Stack>
    );
  }
  if (!singlePage) {
    return (
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Box onClick={() => navigate(-1)} component={'img'} src={BackIcon} sx={commonIconStyles} />
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/project/projects/${project.id}`)}>
          <Typography variant="headBreadCrumps" component={'p'} sx={{ color: '#2E2E2E' }}>
            {project?.name}
          </Typography>
        </Box>
        {category && (
          <>
            <KeyboardArrowRight color="#0B132B" sx={{ fontSize: '32px' }} />
            <Typography
              variant="headBreadCrumps"
              component={'p'}
              sx={{ cursor: 'pointer', color: '#0B132B' }}
              onClick={() => navigate(`/dashboard/project/projects/task?category=${category.name}&id=${project.id}`)}
            >
              {category?.name}
            </Typography>
          </>
        )}
        {task && (
          <>
            <KeyboardArrowRight color="#0B132B" sx={{ fontSize: '32px' }} />
            <Typography
              variant="headBreadCrumps"
              component={'p'}
              sx={{ cursor: 'pointer', color: '#0B132B' }}
              onClick={() =>
                navigate(`/dashboard/project/projects/task/view?projectId=${project.id}&taskId=${task.id}`)
              }
            >
              {task?.name}
            </Typography>
          </>
        )}
      </Stack>
    );
  }
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <Box onClick={() => navigate(-1)} component={'img'} src={BackIcon} sx={commonIconStyles} />
      <Box sx={{ cursor: 'pointer' }}>
        <Typography variant="headBreadCrumps" component={'p'} sx={{ color: '#2E2E2E' }}>
          {singlePage}
        </Typography>
      </Box>
    </Stack>
  );
}

export default BreadCrumps;
