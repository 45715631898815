import { Container, Grid, Stack, Button, TablePagination, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import PieChart from '@mui/icons-material/PieChart';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import TaskCard from '../components/TaskCard';
import BreadCrumps from '../components/BreadCrumps';

export const SubTaskView = () => {
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 9;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const id = searchParams.get('id');

  const getTask = (newPage) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/category/all`, {
        params: { projectId: id, category, page: newPage, size },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.data);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    getTask(newPage);
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <Page>
      <Container maxWidth="xl">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={5} rowGap={3}>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  marginBottom: '-30px',
                }}
                spacing={2}
              >
                <BreadCrumps
                  project={{ 
                    name: task && task[0]?.projectData?.name,
                    id: task && task[0]?.projectData?._id,
                  }}
                  category={{ name: category }}
                />

                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Button
                    startIcon={<PieChart />}
                    variant="outlined"
                    color="buttonone"
                    onClick={() => navigate(`/dashboard/category-analytics/?category=${category}&id=${id}`)}
                    sx={{
                      borderWidth: '2px',
                      ':hover': {
                        borderWidth: '2px',
                      },
                      fontFamily: 'Sora',
                    }}
                  >
                    Analytics
                  </Button>
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    color="buttonone"
                    onClick={() => navigate(`/dashboard/project/projects/task/create?category=${category}&id=${id}`)}
                    sx={{ fontFamily: 'Sora' }}
                  >
                    Task
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} rowGap={2}>
                {task?.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <TaskCard task={item} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={taskCount}
          page={pages}
          onPageChange={handlePageChange}
          rowsPerPage={size}
        />
      </Container>
    </Page>
  );
};
