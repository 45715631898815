import * as React from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { TextField, Button, Stack, Box, MenuItem, Collapse, Grid } from '@mui/material';

const Filter = ({ open, filterValues, applyFilters }) => (
  <Collapse in={open}>
    <Formik
      initialValues={{
        invoiceOfTheMonth: filterValues.invoiceOfTheMonth,
        isPaid: filterValues.isPaid,
        invoiceNumber: filterValues.invoiceNumber,
        startDate: filterValues.startDate ? moment.utc(filterValues.startDate).format('YYYY-MM-DD') : '',
        endDate: filterValues.endDate ? moment.utc(filterValues.endDate).format('YYYY-MM-DD') : '',
        isOverDue: filterValues.isOverDue,
      }}
      onSubmit={async (values) => {
        applyFilters(values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="invoiceOfTheMonth"
                name="invoiceOfTheMonth"
                label="Invoice Of The Month"
                value={values.invoiceOfTheMonth}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Is Paid"
                value={values.isPaid}
                name="isPaid"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="false">NO</MenuItem>
                <MenuItem value="true">YES</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="invoiceNumber"
                name="invoiceNumber"
                type="invoiceNumber"
                label="Invoice Number"
                value={values.invoiceNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Is Overdue"
                value={values.isOverDue}
                name="isOverDue"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="false">NO</MenuItem>
                <MenuItem value="true">YES</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction={'row'} spacing={2}>
                <TextField
                  type="date"
                  name="startDate"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  value={values.startDate}
                  onChange={handleChange}
                />
                <p style={{ paddingTop: '15px' }}>to</p>
                <TextField
                  type="date"
                  name="endDate"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  value={values.endDate}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit" style={{ marginTop: '10px' }} color="buttonone">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Collapse>
);

export default Filter;
