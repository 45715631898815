// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={23} height={23} />;

const getSubIcon = (name) => <Iconify icon={name} width={18} height={18} />;

const topConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('ic:round-dashboard'),
  },
  {
    title: 'general',
    path: '/dashboard/general',
    icon: getIcon('fluent:settings-20-filled'),
    children: [
      {
        title: 'client',
        path: '/dashboard/general/client',
        icon: getSubIcon('streamline:information-desk-customer-solid'),
      },
      {
        title: 'employees',
        path: '/dashboard/general/employee',
        icon: getSubIcon('fluent:people-team-28-filled'),
      },
      {
        title: 'settings',
        path: '/dashboard/general/settings',
        icon: getSubIcon('solar:settings-bold'),
      },
    ],
  },
  {
    title: 'CRM',
    path: '/dashboard/crm',
    icon: getIcon('streamline:customer-support-1-solid'),
    children: [
      {
        title: 'leads',
        path: '/dashboard/crm/lead',
        icon: getSubIcon('mdi:leads'),
      },
      {
        title: 'settings',
        path: '/dashboard/crm/settings',
        icon: getSubIcon('solar:settings-bold'),
      },
    ],
  },
  {
    title: 'HR',
    path: '/dashboard/hr',
    icon: getIcon('fluent:people-team-28-filled'),
    children: [
      {
        title: 'attendance',
        path: '/dashboard/hr/attendance',
        icon: getSubIcon('ic:baseline-co-present'),
      },
      {
        title: 'work log',
        path: '/dashboard/hr/work-log',
        icon: getSubIcon('pajamas:log'),
      },
      {
        title: 'leave',
        path: '/dashboard/hr/leave',
        icon: getSubIcon('ic:round-contact-mail'),
      },
    ],
  },
  {
    title: 'project',
    path: '/dashboard/project',
    icon: getIcon('ant-design:project-filled'),
    children: [
      {
        title: 'projects',
        path: '/dashboard/project/projects',
        icon: getSubIcon('ant-design:project-filled'),
      },
      {
        title: 'tasks',
        path: '/dashboard/project/to-do',
        icon: getSubIcon('fluent:tasks-app-28-filled'),
      },
      {
        title: 'settings',
        path: '/dashboard/project/settings',
        icon: getSubIcon('solar:settings-bold'),
      },
    ],
  },
  {
    title: 'Invoices',
    path: '/dashboard/invoice',
    icon: getIcon('solar:bill-list-bold'),
  }
];

const bottomConfig = [
  {
    title: 'admin',
    path: '/dashboard/admin',
    icon: getSubIcon('eos-icons:admin'),
  },
];

export { topConfig, bottomConfig };
