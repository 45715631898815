import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Tooltip } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  fontFamily: 'Sora',
  fontWeight: 600,
}));

const ListItemSubStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  fontFamily: 'Sora',
  fontWeight: 400,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ListItemSubIconStyle = styled(ListItemIcon)({
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const { isCollapsed } = useSelector((state) => state.sidebarCollapse);

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.main',
  };

  if (children) {
    return (
      <>
        <Tooltip title={isCollapsed ? title : ''} placement="right" arrow>
          <ListItemStyle 
            onClick={handleOpen}
            sx={{
              // ...(isActiveRoot && activeRootStyle),
              justifyContent: isCollapsed ? 'center' : 'space-between',
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            {!isCollapsed && <ListItemText disableTypography primary={title} />}
            {info && info}
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, display: isCollapsed ? 'none' : 'block' }}
            />
          </ListItemStyle>
        </Tooltip>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ marginLeft: isCollapsed ? 0 : 2 }}>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemSubStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                    justifyContent: isCollapsed ? 'center' : 'space-between',
                  }}
                >
                  <ListItemSubIconStyle>{item.icon && item.icon}</ListItemSubIconStyle>
                  {!isCollapsed && (
                    <ListItemText
                      disableTypography
                      primary={title}
                      sx={{
                        fontSize: '13px',
                        fontWeight: 400,
                      }}
                    />
                  )}
                </ListItemSubStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Tooltip title={isCollapsed ? title : ''} placement="right" arrow>
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
          justifyContent: isCollapsed ? 'center' : 'space-between',
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        {!isCollapsed && <ListItemText disableTypography primary={title} />}
        {info && info}
      </ListItemStyle>
    </Tooltip>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
