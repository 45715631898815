// @mui
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// components
// sections
import AddLeadForm from './AddLeadForm';

// ----------------------------------------------------------------------





const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // backgroundColor: 'black',
  // padding: theme.spacing(12, 0),
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddLead({ closePopup, type }) {

  return (
    <Container>
      <ContentStyle>
        <AddLeadForm closePopup={closePopup} type={type} />
      </ContentStyle>
    </Container>
  );
}
