import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Container, Grid, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { CheckCircle, CheckCircleOutlineOutlined } from '@mui/icons-material';
import ProgressBar from '@ramonak/react-progress-bar';
import PieChartCount from '../graphs/PieChartCount';
import Page from '../components/Page';
import PieChartProjectStorage from '../graphs/PieChartProjectStorage';
import MemberList from '../components/MemberList';
import BackIcon from '../assets/icons/back.png';

export default function CategoryAnalytics() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const id = searchParams.get('id');
  const [taskCompleted, setTaskCompleted] = useState(0);
  const [taskCountsGraphData, setTaskCountsGraphData] = useState([]);
  const [storageUsage, setStorageUsage] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [graphLoader, setGraphLoader] = useState(true);
  const [graphLoader1, setGraphLoader1] = useState(true);
  const [storageFormat, setStorageFormat] = useState('');
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTasksCompleted = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project-category/task/progress/`, {
        params: { projectId: id, category },
      });
      console.log('taskCompleted', res);
      setTaskCompleted(res.data.data.completed);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskCountsPieData = async () => {
    try {
      setGraphLoader1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project-category/tasks`, {
        params: { projectId: id, category },
      });
      console.log('tasks status pie', res);
      setTaskCountsGraphData(res.data.data.data);
      setGraphLoader1(false);
    } catch (error) {
      console.log(error);
      setGraphLoader1(false);
    }
  };

  const getStoragePieData = async () => {
    try {
      setGraphLoader(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project-category/task/storage`, {
        params: { projectId: id, category },
      });
      console.log('storage pie', res);
      setStorageUsage(res.data.data.data);
      setStorageFormat(res.data.data.format);
      setGraphLoader(false);
    } catch (error) {
      console.log(error);
      setGraphLoader(false);
    } 
  };

  const getMembers = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project-category/members/`, {
        params: { projectId: id, category },
      });
      console.log('memberss', res);
      setMembers(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getTaskList = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project-category/tasklist`, {
        params: { projectId: id, category },
      });
      console.log('taskList', res);
      setTaskList(res.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTasksCompleted();
    getMembers();
    getTaskCountsPieData();
    getTaskList();
    getStoragePieData();
  }, []);

  return (
    <Page title="Analytics">
      <Container maxWidth="xl">
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            marginBottom: '-30px',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2} marginBottom={3}>
            <Box
              onClick={() => navigate(-1)}
              component={'img'}
              src={BackIcon}
              sx={{
                width: '32px',
                height: '32px',
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{
                color: '#0B132B',
                fontFamily: 'Sora',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.11px',
              }}
            >
              {category}
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Grid container spacing={4} rowGap={4}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '-0.1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Tasks Completed
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <ProgressBar
                    completed={taskCompleted}
                    baseBgColor="#C0BEBE"
                    bgColor={taskCompleted < 60 ? '#0D4DA2' : '#0ED652'}
                    maxCompleted={100}
                    height="10px"
                    width="100%"
                    customLabelStyles={{
                      fontSize: '10px',
                      color: `${taskCompleted < 60 ? '#0D4DA2' : '#0ED652'}`,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '-0.1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {taskCompleted}%
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8} lg={8.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      background: '#fff',
                      borderRadius: '12px',
                      cursor: 'pointer',
                      height: '100%',
                    }}
                    px={3}
                    py={3}
                  >
                    <Typography
                      textAlign={'center'}
                      sx={{
                        color: '#2E2E2E',
                        fontFamily: 'Sora',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.09px',
                      }}
                      mb={3}
                    >
                      {category}
                    </Typography>
                    {/* <Typography
                      sx={{
                        color: '#2E2E2E',
                        textAlign: 'end',
                        fontFamily: 'Work Sans',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                      mb={0.5}
                    >{`${item?.completedTaskCount}/${item?.totalTaskCount}`}</Typography>
                    <Box sx={{ width: '100%' }} mb={4}>
                      <ProgressBar
                        completed={item.completedTaskPercentage}
                        baseBgColor="#C0BEBE"
                        bgColor={item.completedTaskPercentage < 60 ? '#5297FF' : '#0ED652'}
                        maxCompleted={100}
                        height="10px"
                        width="100%"
                        customLabelStyles={{
                          fontSize: '10px',
                          color: `${item.completedTaskPercentage < 60 ? '#5297FF' : '#0ED652'}`,
                        }}
                      />
                    </Box> */}
                    <Stack
                      direction={'column'}
                      spacing={3}
                      sx={{
                        height: { xs: 'auto', sm: '100%' },
                        overflowY: { xs: 'auto', sm: 'scroll' },
                      }}
                      className="scrollCard"
                    >
                      {taskList.map((name, id) => (
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={id}>
                          <Typography
                            sx={{
                              color: name?.status === 'COMPLETED' ? '#919191' : '#414141',
                              fontFamily: 'Work Sans',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: 'normal',
                              letterSpacing: '-0.08px',
                            }}
                          >
                            {name.name}
                          </Typography>

                          <Tooltip
                            title={name?.status?.replaceAll('_', ' ')}
                            placement="top"
                            TransitionComponent={Zoom}
                            arrow
                          >
                            {name?.status === 'COMPLETED' ? (
                              <CheckCircle sx={{ color: '#53D0B3' }} />
                            ) : (
                              <CheckCircleOutlineOutlined sx={{ color: '#53D0B3' }} />
                            )}
                          </Tooltip>
                        </Stack>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <PieChartCount
                    title="TASKS"
                    chartColors={['#EE4B2B', '#CF9FFF', '#FF7518', '#98FB98', '#50688C']}
                    chartData={taskCountsGraphData}
                    graphloader={graphLoader1}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <PieChartProjectStorage
                    title="STORAGE USED"
                    chartData={storageUsage}
                    graphloader={graphLoader}
                    format={storageFormat}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={3.5}>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <MemberList members={members} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
