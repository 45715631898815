import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
// component
import axios from 'axios';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function AddHeirarchyForm({ closePopup, getTaskLists }) {
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const HeirarchySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    priority: Yup.number().required('Priority is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      priority: 0,
      isBlocked: 'true',
    },
    validationSchema: HeirarchySchema,
    onSubmit: async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/taskList`, values, {});
        console.log(response);
        if (!response.data.isError) {
          toast.success(response.data.message);
          getTaskLists();
          closePopup();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        return false;
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2} mt={1}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Name"
              placeholder="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Priority"
              placeholder="Priority"
              type="number"
              {...getFieldProps('priority')}
              error={Boolean(touched.priority && errors.priority)}
              helperText={touched.priority && errors.priority}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              placeholder="Status"
              size="small"
              name="isBlocked"
              {...getFieldProps('isBlocked')}
              onChange={formik.handleChange}
            >
              <MenuItem value={'false'}>Active</MenuItem>
              <MenuItem value={'true'}>Blocked</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ fontFamily: 'Inter' }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
