import { FilterList } from '@mui/icons-material';
import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function MemberAvatar({ member }) {
  const navigate = useNavigate();
  return (
    <Grid item xs={4} md={4} lg={4}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={0.5}
        sx={{
          height: '90px',
          paddingY: '4px',
          paddingX: '8px',
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate(`/dashboard/member/${member._id}`);
        }}
      >
        <Avatar
          sx={{
            width: '50px',
            height: '50px',
          }}
          alt={member?.fullname?.toUpperCase()}
          src={member?.profilePic}
        />
        <Typography
          textAlign={'center'}
          variant="body1"
          sx={{
            fontWeight: 400,
            color: '#000000',
            fontFamily: 'Sora, sans-serif',
            width: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {member?.fullname?.toUpperCase()}
        </Typography>
      </Stack>
    </Grid>
  );
}

function MembersCard({ members }) {
  console.log(members);
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={3}
      sx={{ borderRadius: '8px', background: '#fff' }}
      py={3}
      px={2.5}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent="space-between "
        sx={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#0B132B',
          }}
          variant="headMain"
          component={'p'}
        >
          Customers
        </Typography>
      </Stack>
      <Grid container rowGap={2}>
        {members?.map((member, index) => (
          <MemberAvatar key={index} member={member?.client} />
        ))}
      </Grid>
    </Stack>
  );
}

export default MembersCard;
