import { MoreVert } from '@mui/icons-material';
import { Avatar, AvatarGroup, Badge, Box, Icon, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ProjectCard({ project }) {
  const navigate = useNavigate();
  const id = project._id;
  const [taskCompleted, setTaskCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getTasksCompleted = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/task/progress/`, {
        params: { projectId: id },
      });
      console.log('taskCompleted', res);
      setTaskCompleted(res.data.data.completed);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTasksCompleted();
  }, []);

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{
        borderRadius: '6px',
        padding: 3,
        cursor: 'pointer',
        backgroundColor: '#fff',
        width: '100%',
      }}
      onClick={() => navigate(`/dashboard/project/projects/${project._id}`)}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt="" src={project?.projectImage}>
            {project?.name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '200px',
              fontWeight: 600,
              fontSize: '16px',
              color: '#2E2E2E',
              fontFamily: 'Sora , sans-serif',
            }}
          >
            {project?.name}
          </Typography>
        </Stack>
        <MoreVert />
      </Stack>
      <Box sx={{ height: '60px' }}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 400,
            fontSize: '16px',
            color: 'rgba(46, 46, 46, 0.70)',
            fontFamily: 'Work Sans , sans-serif',
            letterSpacing: '-0.016px',
          }}
        >
          {project?.description?.length > 100 ? `${project?.description?.substring(0, 100)}...` : project?.description}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={1}>
          <Typography
            sx={{
              color: '#2E2E2E',
              fontFamily: 'Work Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            Manager
          </Typography>
          <Tooltip TransitionComponent={Zoom} title={project?.managerData?.email}>
            <Avatar
              alt=""
              src={project?.managerData?.profilePic}
            >
              {project?.managerData?.fullname.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            width: '100px',
          }}
        >
          <Typography
            sx={{
              color: '#2E2E2E',
              fontFamily: 'Work Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            Members
          </Typography>
          <AvatarGroup max={3} sx={{ display: 'flex', justifyContent: 'left' }}>
            {project?.memberData.map((mdata, id) => (
              <Tooltip key={id} TransitionComponent={Zoom} title={mdata.email}>
                <Badge
                  invisible={!mdata.isBlocked}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={<Icon fontSize="15px" icon="material-symbols:block" color="red" />}
                >
                  <Avatar alt="" key={id} src={mdata.profilePic}>
                    {mdata.fullname.charAt(0).toUpperCase()}
                  </Avatar>
                </Badge>
              </Tooltip>
            ))}
          </AvatarGroup>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography
          sx={{
            color: '#2E2E2E',
            fontFamily: 'Work Sans',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Progress
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <ProgressBar
              completed={taskCompleted}
              baseBgColor="#C0BEBE"
              bgColor={taskCompleted < 60 ? '#5297FF' : '#0ED652'}
              maxCompleted={100}
              height="10px"
              width="100%"
              customLabelStyles={{
                fontSize: '10px',
                color: `${taskCompleted < 60 ? '#5297FF' : '#0ED652'}`,
              }}
            />
          </Box>
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Work Sans',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
          >
            {taskCompleted}%
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ProjectCard;
