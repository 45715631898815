import { CircularProgress } from '@mui/material';
import React from 'react';

function LoadingIcon() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default LoadingIcon;
