import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
    Button,
    Card,
    CircularProgress,
    Container,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import Moment from 'moment';
import ReactCurrencyFormatter from 'react-currency-formatter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { RemoveRedEyeRounded } from '@mui/icons-material';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// mock
import Filter from '../components/filters/InvoiceFilter';
import Iconify from '../components/Iconify';

import { commonBodyStyle, commonHeadStyle } from '../theme/commonStyles';

// import { FilterForm } from '../components/FilterForm';

// ----------------------------------------------------------------------

// const customToolBar = ({setFilter})
export default function Invoices() {
    const navigate = useNavigate();

    const [invoiceData, setInvoiceData] = useState([]);

    const [pages, setPages] = useState(0);
    const [maxRecords, setMaxRecords] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const size = 10;
    const [options, setOptions] = useState({ size, page: 0 });
    const [input, setInput] = useState(1);
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        getInvoices(options);
    }, [options]);

    const getInvoices = (options) => {
        console.log(options);
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/invoice/admin/all`, {
                params: options,
            })
            .then((res) => {
                console.log('ressssssssssss', res);
                if (res.data.isError === false) {
                    setInvoiceData(res.data.data.records);
                    setMaxRecords(res.data.data.maxRecords)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const handlePageChange = (event, newPage) => {
        setPages(newPage);
        const temp = { ...options, page: newPage };
        setOptions(temp);
    };

    const applyFilters = (filter) => {
        console.log('filter', filter);
        const temp = { ...options, page: 0 };
        if (filter.invoiceOfTheMonth) {
            temp.invoiceOfTheMonth = filter.invoiceOfTheMonth;
        }
        if (filter.isPaid) {
            temp.isPaid = filter.isPaid;
        }
        if (filter.startDate.length) {
            temp.startDate = filter.startDate;
        }
        if (filter.endDate.length) {
            temp.endDate = filter.endDate;
        }
        if (filter.invoiceNumber.length) {
            temp.invoiceNumber = filter.invoiceNumber;
        }
        if (filter.isOverDue) {
            temp.isOverDue = filter.isOverDue;
        }
        setOptions(temp);
        setPages(0);
    };

    const goToPage = () => {
        if (input > Math.ceil(maxRecords / size)) {
            return;
        }
        setInput(input > 0 ? input : 1);
        setPages(input - 1 >= 0 ? input - 1 : 0);
        const temp = { ...options, page: input - 1 };
        setOptions(temp);
    };

    return (
        <>
            <Page title="Invoices">
                {isLoading ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <ToastContainer />
                        <Container maxWidth="xl">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography
                                    sx={{
                                        color: '#0B132B',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.1px',
                                    }}
                                    variant="headMain"
                                    component={'p'}
                                >
                                    Invoices
                                </Typography>

                              

                              
                            </Stack>
                            <Card>
                                <Stack direction="row" spacing={2} padding={2} justifyContent={'space-between'}>
                                    <Stack direction="row" spacing={1.5}>
                                        <TextField
                                            size="small"
                                            style={{ maxWidth: '100px' }}
                                            type="number"
                                            component="div"
                                            label="Go to"
                                            value={input}
                                            onInput={(e) => setInput(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={goToPage}
                                            sx={{
                                                backgroundColor: '#0096DB',
                                                height: '40px',
                                            }}
                                        >
                                            Go
                                        </Button>
                                    </Stack>
                                    <Button
                                        color="buttonone"
                                        variant="contained"
                                        onClick={() => setFilterOpen(!filterOpen)}
                                        startIcon={<Iconify icon="ic:round-filter-list" />}
                                    >
                                        Filter
                                    </Button>
                                </Stack>
                                <Filter applyFilters={applyFilters} open={filterOpen} setOpen={setFilterOpen} filterValues={options} />
                                <Divider
                                    sx={{
                                        background: ' rgba(0, 0, 0, 0.50)',
                                        marginBottom: '24px',
                                    }}
                                />
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        <Table>
                                            {/* <UserListHead /> */}
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Invoice date</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Invoice number</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Invoice Month</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Is paid</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Is overdue</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Subtotal</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Grand total</TableCell>
                                                    <TableCell sx={commonHeadStyle}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoiceData?.map((item, id) => (
                                                    <TableRow key={id}>
                                                        <TableCell sx={{ ...commonBodyStyle, width: '60px' }}>{pages * size + (id + 1)}</TableCell>
                                                        <TableCell
                                                            sx={{ ...commonBodyStyle, cursor: 'pointer' }}
                                                        >
                                                            <Typography sx={commonBodyStyle}>{Moment(item.invoiceDate).format('ll')}</Typography>
                                                        </TableCell>
                                                        <CopyToClipboard text={item?.invoiceNumber} onCopy={() => toast.info('invoice number copied')}>
                                                            <TableCell sx={{ ...commonBodyStyle, cursor: 'pointer' }}>
                                                                {item?.invoiceNumber}
                                                            </TableCell>
                                                        </CopyToClipboard>
                                                        <TableCell sx={commonBodyStyle}>{Moment(item.invoiceOfTheMonth).format("MMMM")}</TableCell>
                                                        <TableCell sx={commonBodyStyle}>{item.isPaid ? "YES" : "NO"}</TableCell>
                                                        <TableCell sx={commonBodyStyle}>{item.isOverdue ? "YES" : "NO"}</TableCell>
                                                        <TableCell sx={commonBodyStyle}>
                                                            <ReactCurrencyFormatter
                                                                quantity={item.subTotal}
                                                                currency="INR"
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={commonBodyStyle}>
                                                            <ReactCurrencyFormatter
                                                                quantity={item.grandTotal}
                                                                currency="INR"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Stack direction="row" spacing={1}>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        background: '#52D0B3',
                                                                        '&:hover': {
                                                                            background: '#42a78f',
                                                                        },
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        padding: '7px',
                                                                        borderRadius: '10px',
                                                                    }}
                                                                    onClick={() => navigate(`/dashboard/invoice/view?invoiceId=${item._id}`)}
                                                                >
                                                                    <RemoveRedEyeRounded sx={{ color: '#fff', fontSize: '18px' }} />
                                                                </Box>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component={'div'}
                                    count={maxRecords}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={rowsPerPage}
                                    page={pages}
                                    showFirstButton
                                    showLastButton
                                />
                            </Card>
                        </Container>
                    </>
                )}
            </Page>
        </>
    );
}
