import { useEffect, useState } from 'react';

// @mui
import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
// components
import Page from '../components/Page';

// sections
import AppDetailsCard from '../components/AppDetailsCard';
import AppWebsiteVisits from '../components/AppWebsiteVisits';
import AppWidgetSummaryNoIcon from '../components/AppWidgetSummaryNoIcon';
import CalendarCard from '../components/CalendarCard';
import CategoryCard from '../components/CategoryCard';
import MembersCard from '../components/MembersCard';
import TasksSummaryTable from '../components/TasksSummaryTable';
import PieChartAmount from '../graphs/PieChartAmount';
import PieChartStorage from '../graphs/PieChartStorage';
import {} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [amountGraphData, setAmountGraphData] = useState([]);
  const [storageUsage, setStorageUsage] = useState([]);
  const [storageFormat, setStorageFormat] = useState('');
  const [graphLoader, setGraphLoader] = useState(true);
  const [graphLoader2, setGraphLoader2] = useState(true);
  const [customers, setCustomers] = useState([]);

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/projects`, {});
      console.log(res);
      setCategories(res.data.data.categories);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getPendingAndRecieved = async () => {
    try {
      setGraphLoader(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/amount`, {});
      console.log(res);
      setAmountGraphData(res.data.data.data);
      setGraphLoader(false);
    } catch (error) {
      console.log(error);
      setGraphLoader(false);
    }
  };

  const getStoragePieData = async () => {
    try {
      setGraphLoader2(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/storage`, {});
      console.log('storage', res);
      setStorageUsage(res.data.data.data);
      setGraphLoader2(false);
      setStorageFormat(res.data.data.format);
    } catch (error) {
      console.log(error);
      setGraphLoader2(false);
    }
  };

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/orgCustomer/admin/all`, {
        params: {
          page: 0,
          size: 20,
        },
      });
      console.log(res);
      setCustomers(res?.data?.data?.records);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
    getPendingAndRecieved();
    getStoragePieData();
    getCustomers();
  }, []);

  const [configs, setConfigs] = useState({});

  const fetchConfigs = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/configuration/admin`);
      setConfigs(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  const [memberCounts, setMemberCounts] = useState({});

  const fetchMemberCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/counts`);
      setMemberCounts(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMemberCounts();
  }, []);

  const [totalStorageBarGraphData, setTotalStorageBarGraphData] = useState({});

  const fetchStorageUsedGraph = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/storage-used-bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      if (!res.data.isError) {
        console.log(res.data.data);
        setTotalStorageBarGraphData(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [invoiceGraph, setInvoiceGraph] = useState({});

  const fetchInvoiceGraph = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/invoice-bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      if (!res.data.isError) {
        console.log(res.data.data);
        setInvoiceGraph(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [monthlyAmount, setMonthlyAmount] = useState(0);

  const fetchMonthlyAmount = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/current-month-amount`);
      if (!res.data.isError) {
        console.log(res.data.data);
        setMonthlyAmount(res.data.data.total);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const trueValue = true;

  useEffect(() => {
    fetchStorageUsedGraph();
    fetchInvoiceGraph();
    fetchMonthlyAmount();
  }, []);

  return (
    <>
      <Page title="Dashboard">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',

              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#0B132B',
                    marginBottom: '26px',
                  }}
                  variant="headMain"
                  component={'p'}
                >
                  Categories
                </Typography>
                <Grid container spacing={3}>
                  {categories.map((category, id) => (
                    <Grid key={id} item xs={12} md={4} lg={2}>
                      <CategoryCard category={category} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={7}>
                <MembersCard members={customers} />
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <Box sx={{ width: '100%' }} display={'flex'} justifyContent={'center'}>
                  <CalendarCard />
                </Box>
              </Grid>
              {configs?.currentData?.planExists && (
                <Grid item xs={12} md={12} lg={4}>
                  <Box sx={{ width: '100%' }}>
                    <AppDetailsCard
                      memberCount={configs?.currentData?.memberCount}
                      membersUsed={configs?.usageData?.membersUsed}
                      planPrice={configs?.currentData?.planPrice}
                      startDate={configs?.currentData?.startDate}
                      storageLimit={(configs?.currentData?.storageLimit ?? 0) * 1024 * 1024 * 1024}
                      storageUsed={configs?.usageData?.storageUsed}
                      current={trueValue}
                      title={'CURRENT PLAN'}
                    />
                  </Box>
                </Grid>
              )}
              {configs?.futureData?.planExists && (
                <Grid item xs={12} md={12} lg={4}>
                  <Box sx={{ width: '100%' }}>
                    <AppDetailsCard
                      memberCount={configs?.futureData?.memberCount}
                      membersUsed={configs?.futureData?.membersUsed}
                      planPrice={configs?.futureData?.planPrice}
                      startDate={configs?.futureData?.startDate}
                      storageLimit={(configs?.futureData?.storageLimit ?? 0) * 1024 * 1024 * 1024}
                      title={'FUTURE PLAN'}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3} lg={4}>
                <Card
                  component={Stack}
                  spacing={3}
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    px: 3,
                    py: 5,
                    borderRadius: 2,
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Stack
                    spacing={2}
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h4">₹ {monthlyAmount || 0}</Typography>

                    <Typography variant="subtitle2" textAlign={'center'} sx={{ color: 'text.disabled' }}>
                      Monthly Amount
                    </Typography>
                  </Stack>
                </Card>
              </Grid>

              {/* <Grid item xs={12} md={12} lg={12}>
                <Graph data={categories} />
              </Grid> */}
              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <TasksSummaryTable />
                  </Grid>
                  <Grid item xs={8}>
                    <AppWebsiteVisits
                      api={fetchInvoiceGraph}
                      graphTypeProp={'DAY'}
                      title="Invoices"
                      xaxis="Date"
                      yaxis="storage"
                      chart={{
                        labels: invoiceGraph?.paidInvoiceData?.chartLabels,
                        series: [
                          {
                            name: 'Paid Invoice',
                            type: 'column',
                            fill: 'solid',
                            data: invoiceGraph?.paidInvoiceData?.chartData,
                          },
                          {
                            name: 'Not Paid Invoice',
                            type: 'column',
                            fill: 'solid',
                            data: invoiceGraph?.notPaidInvoiceData?.chartData,
                          },
                        ],
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <PieChartAmount
                      title="Payments"
                      chartData={amountGraphData}
                      graphloader={graphLoader}
                      chartColors={['#50C878', '#E35335']}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Members</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon title="Total Members" total={memberCounts?.totalMembers} color="success" />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon
                  title="Suspended Members "
                  total={memberCounts?.suspendedMembers}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon title="Active Members " total={memberCounts?.activeMembers} color="success" />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon
                  title="Today's Members "
                  total={memberCounts?.totalMembersToday}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon
                  title="Weekly Members "
                  total={memberCounts?.totalMembersThisWeek}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon
                  title="Monthly Members "
                  total={memberCounts?.totalMembersThisMonth}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <AppWidgetSummaryNoIcon
                  title="Yearly Members "
                  total={memberCounts?.totalMembersThisYear}
                  color="success"
                />
              </Grid>

              <Grid item xs={8}>
                <AppWebsiteVisits
                  api={fetchStorageUsedGraph}
                  graphTypeProp={'DAY'}
                  title="Storage Used"
                  xaxis="Date"
                  yaxis="storage"
                  // subheader="(+43%) than last year"
                  chart={{
                    labels: totalStorageBarGraphData?.chartLabels,
                    series: [
                      {
                        name: 'Storage Used',
                        type: 'column',
                        fill: 'solid',
                        data: totalStorageBarGraphData?.chartData,
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <PieChartStorage
                  chartColors={['#50C878', '#E35335']}
                  title="Storage"
                  chartData={storageUsage}
                  format={storageFormat}
                  graphloader={graphLoader2}
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Page>
    </>
  );
}
