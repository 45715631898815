import { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination, 
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { RemoveRedEyeRounded } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import Scrollbar from './Scrollbar';
import { commonHeadStyle, commonBodyStyle, boxStyle } from '../theme/commonStyles';

function TasksSummaryTable() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCount, setDataCount] = useState(0);
  const page = 0;
  const size = 5;
  const [options, setOptions] = useState({ size, page, memberId: id });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getData(temp);
    console.log(temp);
  };

  const getData = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/subtask/admin/project/summary`, {
        params: options,
      });
      console.log(res.data);
      setTableData(res.data.data.records);
      setDataCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(options);
  }, []);

  return (
    <Box sx={boxStyle}>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Scrollbar>
          <TableContainer>
            <Table>
              {/* <UserListHead /> */}
              <TableHead>
                <TableRow>
                  <TableCell sx={commonHeadStyle}>Project Name</TableCell>
                  <TableCell sx={commonHeadStyle}>Not Completed </TableCell>
                  <TableCell sx={commonHeadStyle}>Completed </TableCell>
                  <TableCell sx={commonHeadStyle}>Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, id) => (
                  <TableRow hover key={id}>
                    <TableCell sx={commonBodyStyle}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt="" src={row.projectImage}>
                          {row?.projectName?.charAt(0)?.toUpperCase()}
                        </Avatar>
                        <Typography>{row.projectName}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={commonBodyStyle}>{row.notCompleted}</TableCell>
                    <TableCell sx={commonBodyStyle}>{row.completed}</TableCell>
                    <TableCell align="center">
                      <Stack direction="row" spacing={1}>
                        <Box
                          sx={{
                            cursor: 'pointer',
                            background: '#52D0B3',
                            '&:hover': {
                              background: '#42a78f',
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '7px',
                            borderRadius: '10px',
                          }}
                          onClick={() => navigate(`/dashboard/project/projects/${row.projectId}`)}
                        >
                          <RemoveRedEyeRounded sx={{ color: '#fff', fontSize: '18px' }} />
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      )}
      <Box>
        <TablePagination
          rowsPerPageOptions={[]}
          component={'div'}
          count={dataCount}
          onPageChange={handlePageChange}
          rowsPerPage={size}
          page={options.page}
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  );
}

export default TasksSummaryTable;
