import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const PopupIsBlockedChange = ({ item, handleClose, path, type, api }) => {
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}${path}`, {
        id: item._id,
        isBlocked: !item.isBlocked,
      })
      .then((res) => {
        toast.success(res.data.message);
        handleClose();
        api();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!item.isBlocked
              ? `Are you sure you want to block the ${type} ?`
              : `Are you sure you want to unblock the ${type} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupIsBlockedChange;
