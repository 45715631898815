import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  Box,
  Stack,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogAction,
  Tooltip,
  Zoom,
  Fade,
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Divider,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItem,
  Avatar,
  ListItemAvatar,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Form, useFormik, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

export default function ChangeManagerPopup(props) {
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);

  const { id } = useParams();

  const getMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/all-sorted`);
      console.log('members', res);
      setMembers(res.data.data.members);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      projectId: props?.projectId || id,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/project/replace-manager`, values)
          .then((res) => {
            toast.success(res.data.message);
            props.handleClose(true, true);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });
  const handleClose = () => {
    props.handleClose();
  };
  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  useEffect(() => {
    getMembers();
    console.log(members);
  }, []);
  return (
    <div>
      <Dialog
        open={props.popup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
            {'Replace Manager'}
            <Tooltip TransitionComponent={Zoom} title="Close">
              <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
            </Tooltip>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormikProvider value={formik}>
              <Form onSubmit={handleSubmit}>
                <Stack direction="column" sx={{ width: '250px', paddingTop: 2 }}>
                  <FormControl>
                    <TextField
                      select
                      placeholder="Select Category"
                      autoWidth
                      label="Select a member"
                      {...getFieldProps('managerId')}
                      error={Boolean(touched.managerId && errors.managerId)}
                      helperText={touched.managerId && errors.managerId}
                    >
                      {members?.map((item, key) => (
                        <MenuItem key={key} value={item._id}>
                          {item.fullname}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                    <Button variant="contained" type="submit" sx={{ width: '100px' }} color="buttonone">
                      Add
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
