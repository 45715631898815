import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Typography, Grid, Button, Box, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { loginFail, loginSuccess, loginPending } from './LoginSlice';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isAuth, error } = useSelector((state) => state.login);

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(6, 'Too Short!').max(20, 'Too Long!').required('Password required'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/account/admin/signin`, values);
        console.log(res);
        if (res.data.message !== 'Admin Login Successful') {
          dispatch(loginFail(res.data.message));
          toast.error(res.data.message);
        } else {
          dispatch(loginSuccess(res.data.data.admin));
          localStorage.setItem('accessToken', res.data.data.admin.accessToken);
          localStorage.setItem('profile', JSON.stringify(res.data.data.admin));
          navigate('/dashboard/app');
        }
      } catch (error) {
        console.log(error.message);
      }

      // navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange, handleBlur } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };



  const renderForm = (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container columnSpacing={2} rowGap={2}>
            <Grid item xs={12} md={12}>
              <TextField type="email"

                name="email"
                label="Email address *"
                variant="outlined"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
                value={values.email} />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="password"
                label="Password *"
                variant="outlined"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type={showPassword ? 'text' : 'password'}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>


            <Grid item xs={12} mt={2}>
              <Button
                fullWidth
                size="large"
                type="submit"
                // variant="contained"
                color="inherit"
                sx={{
                  background: "#131331",
                  color: "white",
                  ":hover": {
                    background: "#131350",
                  }
                }}
              >
                Login
              </Button>
            </Grid>



          </Grid>
        </Form>
      </FormikProvider>
    </>
  );



  return (
    <Box
      sx={{
        // ...bgGradient({
        // color: "red",
        // imgUrl: '/assets/background/overlay_4.jpg',
        // }),
        height: 1,
      }}
    >
      {/* <Logo
                    sx={{
                        position: 'fixed',
                        top: { xs: 16, md: 24 },
                        left: { xs: 16, md: 24 },
                    }}
                /> */}

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 580,
            // background: "#131331",
          }}
        >
          <Typography variant="h4" >Signin to StackArch</Typography>

          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            Don't have an account?
            <Link variant="subtitle2" onClick={() => navigate("/register")} sx={{ ml: 0.5, cursor: "pointer" }}>
              Register here
            </Link>
          </Typography>

          {renderForm}


        </Card>
      </Stack>
    </Box>
  );
}
