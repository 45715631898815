import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const AddNote = (props) => {
  const matches = useMediaQuery('(max-width:600px)');

  const { getNotes, open, setOpen, userId } = props;

  const quotationSchema = Yup.object().shape({
    note: Yup.string().required('Note is required'),
    id: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      note: '',
      id: userId,
    },
    validationSchema: quotationSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/member/add-note`, values);
        console.log(res.data);
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getNotes();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  useEffect(() => {
    formik.setFieldValue('id', userId);
  }, [userId]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{'Add Note'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="Note"
                    {...getFieldProps('note')}
                    error={Boolean(touched.note && errors.note)}
                    helperText={touched.note && errors.note}
                  />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  {'Submit'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddNote;
