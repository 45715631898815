import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

import { BorderColor, Edit } from '@mui/icons-material';
import Page from '../components/Page';
import MemberCalendarCard from '../components/MemberCalendarCard';
import MemberTasksTable from '../components/MemberTasksTable';
import MemberProjectCount from '../components/MemberProjectCount';
import MemberNotes from '../components/MemberNotes';
import BreadCrumps from '../components/BreadCrumps';
import EditUserForm from '../components/EditUserForm';

import { boxStyle } from '../theme/commonStyles';
import Iconify from '../components/Iconify';
import EditLeadForm from '../components/EditLeadForm';

// ----------------------------------------------------------------------

const nameStyle = {
  color: '#000',
  fontFamily: 'Sora',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
};

const detailsStyle = {
  color: '#555',
  fontFamily: 'Sora',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
};

const PrintComponent = ({ head, body }) => (
  <Stack direction="column" alignItems="start">
    <Typography sx={nameStyle}>{head}</Typography>
    <Typography sx={detailsStyle}>{body}</Typography>
  </Stack>
);

function CustomerView() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState({});
  const [remarks, setRemarks] = useState("")


  const getLead = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/orgCustomer/admin/one`, {
        params: {
          customerId: id,
        },
      });
      setClient(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLead();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/orgCustomer/admin/add-remarks`, {
        customerId: id,
        remarks,
      });

      toast.success(res.data.message);
      setOpen(false);
      getLead();
    } catch (e) {
      toast.error(e.response.data.message);
      console.log(e);
    }
  };

  return (
    <Page title="Customer Details">
      <Container maxWidth="xl">
        <Grid container spacing={5} rowGap={2}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid item>

                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    marginBottom: '-30px',
                  }}
                >
                  <BreadCrumps singlePage={'Customer Details'} />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Stack direction={'row'} justifyContent={'end'} marginBottom={2}>
                    <Button variant="contained" onClick={handleOpen}>
                      <Iconify icon="ri:mark-pen-fill" />
                      &nbsp; Add Remarks
                    </Button>
                  </Stack>
                  <Grid container spacing={3} rowGap={1}>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <PrintComponent head="Fullname" body={client.clientId?.fullname} />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <PrintComponent head="Email" body={client.clientId?.email} />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <PrintComponent head="Is Joined" body={client?.isJoined ? "YES" : "NO"} />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <PrintComponent head="Joined On" body={client.joinedOn ? moment(client.joinedOn).format("DD MMMM,YYYY") : "N/A"} />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12} >
                      <Card sx={{ height: "100px" }}>
                        <CardContent>
                          <PrintComponent head="Remarks" body={client.remarks} />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            maxWidth: 'auto',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack>
            <Stack alignItems={'center'} direction={'row'} gap={1}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Remarks
              </Typography>
            </Stack>
            <Stack mt={2} direction={'column'} gap={2}>
              <TextField
                label="Remarks"
                name='remarks'
                fullWidth
                InputLabelProps={{ shrink: true }}
                minRows={2}
                multiline
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
              <Button variant="contained" size="large" onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          </Stack>

        </Box>
      </Modal>
    </Page>
  );
}

export default CustomerView;
