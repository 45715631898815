import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ProgressBar from '@ramonak/react-progress-bar';
import PieChartAmount from '../graphs/PieChartAmount';
import PieChartCount from '../graphs/PieChartCount';
import Page from '../components/Page';
import PieChartProjectStorage from '../graphs/PieChartProjectStorage';
import BackIcon from '../assets/icons/back.png';
import MemberList from '../components/MemberList';
import BarAndLineGraph from '../graphs/BarAndLineGraph';

export default function ProjectAnalytics() {
  const navigate = useNavigate();
  const { id } = useParams();
  const matches = useMediaQuery('(max-width:1419px)');
  const [amountGraphData, setAmountGraphData] = useState([]);
  const [taskCountsGraphData, setTaskCountsAmountGraphData] = useState([]);
  const [storageUsage, setStorageUsage] = useState([]);
  const [storageFormat, setStorageFormat] = useState('');
  const [taskCompleted, setTaskCompleted] = useState(0);
  const [graphLoader, setGraphLoader] = useState(true);
  const [graphLoader1, setGraphLoader1] = useState(true);
  const [graphLoader2, setGraphLoader2] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState('');
  const [manager, setManager] = useState({});
  const [members, setMembers] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState({ name: 'Projects', data: [] });

  const getEstimateAndPaidPieData = async () => {
    try {
      setGraphLoader(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/amount`, {
        params: { projectId: id },
      });
      console.log(res);
      setAmountGraphData(res.data.data.data);
      setProject(res.data.data.projectName);
      setGraphLoader(false);
    } catch (error) {
      console.log(error);
      setGraphLoader(false);
    }
  };

  const getTaskCountsPieData = async () => {
    try {
      setGraphLoader1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/tasks`, {
        params: { projectId: id },
      });
      console.log(res);
      setTaskCountsAmountGraphData(res.data.data.data);
      if (!res.data?.isError && res?.data.data?.data.length > 0) {
        setChartLabels(res.data.data.data.map((status) => status._id?.replaceAll('_', ' ')));
        setChartData({
          name: 'Projects',
          data: res.data.data.data.map((status) => status.total),
        });
      }
      setGraphLoader1(false);
    } catch (error) {
      console.log(error);
      setGraphLoader1(false);
    }
  };

  const getTasksCompleted = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/task/progress/`, {
        params: { projectId: id },
      });
      console.log('taskCompleted', res);
      setTaskCompleted(res.data.data.completed);
    } catch (error) {
      console.log(error);
    }
  };
  const getStoragePieData = async () => {
    try {
      setGraphLoader2(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/storage`, {
        params: { projectId: id },
      });
      console.log('storage', res);
      setStorageUsage(res.data.data.data);
      setGraphLoader2(false);
      setStorageFormat(res.data.data.format);
    } catch (error) {
      console.log(error);
      setGraphLoader2(false);
    }
  };

  const getMembers = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/project/members/acctype/`, {
        params: { projectId: id },
      });
      console.log('memberss', res);
      setManager(res.data.data.manager);
      setMembers(res.data.data.members);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEstimateAndPaidPieData();
    getTaskCountsPieData();
    getTasksCompleted();
    getMembers();
    getStoragePieData();
  }, []);

  return (
    <Page title="Project Analytics">
      <Container maxWidth="xl">
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            marginBottom: '-30px',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2} marginBottom={3}>
            <Box
              onClick={() => navigate(-1)}
              component={'img'}
              src={BackIcon}
              sx={{
                width: '32px',
                height: '32px',
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{
                color: '#0B132B',
                fontFamily: 'Sora',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.11px',
              }}
            >
              {project}
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Grid container spacing={4} rowGap={4}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '-0.1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Tasks Completed
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <ProgressBar
                    completed={taskCompleted}
                    baseBgColor="#C0BEBE"
                    bgColor={taskCompleted < 60 ? '#0D4DA2' : '#0ED652'}
                    maxCompleted={100}
                    height="10px"
                    width="100%"
                    customLabelStyles={{
                      fontSize: '10px',
                      color: `${taskCompleted < 60 ? '#0D4DA2' : '#0ED652'}`,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '-0.1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {taskCompleted}%
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={8} lg={8.5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BarAndLineGraph title="TASKS" chartData={chartData} chartLabels={chartLabels} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <PieChartAmount
                    title="PAYMENTS"
                    chartData={amountGraphData}
                    graphloader={graphLoader}
                    chartColors={['#4CBB17', '#FF3131']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <PieChartProjectStorage
                    title="STORAGE" 
                    chartData={storageUsage}
                    format={storageFormat}
                    graphloader={graphLoader2}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={3.5}>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <MemberList members={members} manager={manager} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
