// @mui
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// components
// sections
import AddAttendanceForm from './AddAttendanceForm';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // backgroundColor: 'black',
  // padding: theme.spacing(12, 0),
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddAttendance({ closePopup, getApi, options }) {
  return (
    <Container>
      <ContentStyle>
        <AddAttendanceForm closePopup={closePopup} getApi={getApi} options={options} />
      </ContentStyle>
    </Container>
  );
}
