import { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Scrollbar from './Scrollbar';
import { commonHeadStyle, commonBodyStyle } from '../theme/commonStyles';

function MemberTasksTable() {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const page = 0;
  const size = 5;
  const [options, setOptions] = useState({ size, page, memberId: id });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getData(temp);
    console.log(temp);
  };

  const getData = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/member/stats`, {
        params: options,
      });
      console.log(res.data);
      setTableData(res.data.data.data);
      setUserCount(res.data.data.maxRecord);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(options);
  }, []);

  return (
    <Box>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Scrollbar>
          <TableContainer>
            <Table>
              {/* <UserListHead /> */}
              <TableHead>
                <TableRow>
                  <TableCell sx={commonHeadStyle}>Project Name</TableCell>
                  <TableCell sx={commonHeadStyle}>Pending </TableCell>
                  <TableCell sx={commonHeadStyle}>In progress </TableCell>
                  <TableCell sx={commonHeadStyle}>Completed </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, id) => (
                  <TableRow hover key={id}>
                    <TableCell sx={commonBodyStyle}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt="" src={row.projectImage}>
                          {row.projectName.charAt(0).toUpperCase()}
                        </Avatar>
                        <Typography>{row.projectName}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={commonBodyStyle}>{row.pendingTaskCount}</TableCell>
                    <TableCell sx={commonBodyStyle}>{row.inProgressTaskCount}</TableCell>
                    <TableCell sx={commonBodyStyle}>{row.completedTaskCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      )}
      <TablePagination
        rowsPerPageOptions={[]}
        component={'div'}
        count={userCount}
        onPageChange={handlePageChange}
        rowsPerPage={size}
        page={options.page}
        showFirstButton
        showLastButton
      />
    </Box>
  );
}

export default MemberTasksTable;
