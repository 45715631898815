import { useState } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

// import { useRouter } from 'src/routes/hooks';

// import { bgGradient } from 'src/theme/css';

// import Logo from 'src/components/logo';
// import Iconify from 'src/components/iconify';
import { Formik, useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import axios, { Axios } from 'axios';
// import { useDispatch } from 'react-redux';
// import { loginStart, loginSuccess } from 'src/redux/adminSlice';
import { toast } from 'react-toastify';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../../../components/Iconify';
// import { setAdminToken, setAdminType } from 'src/storeService';
// import axiosInstance from '../../api/axiosConfig';

// ----------------------------------------------------------------------

const loginSchema = Yup.object().shape({
  fullname: Yup.string().required('Fullname is required'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default function RegisterForm() {
  const theme = useTheme();

  // const router = useRouter();

  const navigate = useNavigate();
  // const dispacth = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/account/admin/signup`, {
          email: values.email,
          password: values.password,
          fullname: values.fullname,
          orgAddress: addressFormData,
          contactPerson: contactFormData,
        });
        if (res.data.isError === false) {
          toast.success(res.data.message);
          setVerifyEmail(true);
          setVerificationEmail(res.data.data.email);
        }
        if (res.data.isError === true) {
          toast.error(res.data.message);
        }
      } catch (e) {
        toast.error(e.response.data.message);
        console.log(e);
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const [addressFormData, setAddressFormData] = useState({
    address: '',
    city: '',
    state: '',
    pin: '',
    country: '',
    phone1: '',
    phone2: '',
  });

  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData({
      ...addressFormData,
      [name]: value,
    });
  };
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  };

  const renderForm = (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container columnSpacing={2} rowGap={2}>
            <Grid item xs={12} md={6}>
              <TextField
                type="fullname"
                name="fullname"
                label="Fullname *"
                variant="outlined"
                error={touched.fullname && Boolean(errors.fullname)}
                helperText={touched.fullname && errors.fullname}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.fullname}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="email"
                name="email"
                label="Email address *"
                variant="outlined"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="password"
                label="Password *"
                variant="outlined"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type={showPassword ? 'text' : 'password'}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="confirmPassword"
                label="Confirm Password *"
                variant="outlined"
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                type={showPassword ? 'text' : 'password'}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  Address (optional)
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container columnSpacing={2} rowGap={2}>
                    {Object.keys(addressFormData).map((field, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={field === 'address' ? 12 : field === 'phone1' || field === 'phone2' ? 6 : 3}
                        key={index}
                      >
                        <TextField
                          fullWidth
                          size="small"
                          label={field.charAt(0).toUpperCase() + field.slice(1)}
                          name={field}
                          value={addressFormData[field]}
                          onChange={handleAddressChange}
                          variant="outlined"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  Contact person (optional)
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container columnSpacing={2} rowGap={2}>
                    {Object.keys(contactFormData).map((field, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <TextField
                          fullWidth
                          size="small"
                          label={`Contact Person  ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                          name={field}
                          value={contactFormData[field]}
                          onChange={handleContactChange}
                          variant="outlined"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                type="submit"
                // variant="contained"
                color="inherit"
                sx={{
                  background: '#131331',
                  color: 'white',
                  ':hover': {
                    background: '#131350',
                  },
                }}
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );

  return (
    <Box
      sx={{
        // ...bgGradient({
        color: 'white',
        // imgUrl: '/assets/background/overlay_4.jpg',
        // }),
        height: 1,
      }}
    >
      {/* <Logo
                    sx={{
                        position: 'fixed',
                        top: { xs: 16, md: 24 },
                        left: { xs: 16, md: 24 },
                    }}
                /> */}

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 740,
            // background: "#131331",
          }}
        >
          {!verifyEmail && (
            <>
              <Typography variant="h4">Register to StackArch</Typography>

              <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                Already have an account?
                <Link variant="subtitle2" onClick={() => navigate('/login')} sx={{ ml: 0.5, cursor: 'pointer' }}>
                  Signin here
                </Link>
              </Typography>

              {renderForm}
            </>
          )}

          {verifyEmail && (
            <>
              <Typography variant="h4">Register to StackArch</Typography>

              <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                A verification mail has been sent to <u>{verificationEmail} </u> . Please check your email to complete
                the registration.
              </Typography>
            </>
          )}
        </Card>
      </Stack>
    </Box>
  );
}
