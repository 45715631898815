import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

import { BorderColor, Edit } from '@mui/icons-material';
import Page from '../components/Page';
import MemberCalendarCard from '../components/MemberCalendarCard';
import MemberTasksTable from '../components/MemberTasksTable';
import MemberProjectCount from '../components/MemberProjectCount';
import MemberNotes from '../components/MemberNotes';
import BreadCrumps from '../components/BreadCrumps';
import EditUserForm from '../components/EditUserForm';

import { boxStyle } from '../theme/commonStyles';
import Iconify from '../components/Iconify';
import EditLeadForm from '../components/EditLeadForm';

// ----------------------------------------------------------------------

const nameStyle = {
  color: '#000',
  fontFamily: 'Sora',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
};

const detailsStyle = {
  color: '#555',
  fontFamily: 'Sora',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
};

const PrintComponent = ({ head, body }) => (
  <Stack direction="column" alignItems="start">
    <Typography sx={nameStyle}>{head}</Typography>
    <Typography sx={detailsStyle}>{body}</Typography>
  </Stack>
);

function ViewLead() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [clientExists, setClientExists] = useState();
  const [clientEmail, setClientEmail] = useState('');

  const getLead = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/admin/one`, {
        params: {
          id,
        },
      });
      setLead(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLead();
  }, [id, editMode]);

  useEffect(() => {
    checkClientExists();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [editMode]);

  const checkClientExists = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/admin/check-client`, {
        params: {
          id,
        },
      });
      setClientExists(res.data.data.exists);
    } catch (e) {
      console.log(e);
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    checkClientExists();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const [existingModal, setExistingModal] = useState(false);
  const [newModal, setNewModal] = useState(false);

  const handleExistingModal = () => {
    setNewModal(false);
    setClientEmail(lead.email);
    setExistingModal(true);
  };
  const handleNewModal = () => {
    setExistingModal(false);
    setClientEmail('');
    setNewModal(true);
  };
  const handleClickBack = () => {
    setExistingModal(false);
    setNewModal(false);
  };
  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead/admin/connect`, {
        leadId: id,
        clientEmail,
      });
      toast.success(res.data.message);
      setExistingModal(false);
      setClientEmail('');
      setNewModal(false);
      setOpen(false);
      getLead();
    } catch (e) {
      toast.error(e.response.data.message);
      console.log(e);
    }
  };

  return (
    <Page title="Lead">
      <Container maxWidth="xl">
        <Grid container spacing={5} rowGap={2}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    marginBottom: '-30px',
                  }}
                >
                  <BreadCrumps singlePage={'Lead'} />

                  <IconButton onClick={() => setEditMode(!editMode)}>
                    <BorderColor />
                  </IconButton>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Stack direction={'row'} justifyContent={'end'} marginBottom={2}>
                    {!clientExists && (
                      <Button variant="contained" onClick={handleOpen}>
                        <Iconify icon="ri:user-add-fill" />
                        &nbsp; Connect Client
                      </Button>
                    )}
                  </Stack>
                  {!editMode ? (
                    <Grid container spacing={3} rowGap={1}>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Fullname" body={lead.fullname} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Email" body={lead.email} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Phone" body={lead.phone} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Address" body={lead.address} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Source" body={lead?.source?.name} />
                          </CardContent>
                        </Card>
                      </Grid>
                      {lead?.otherSource && (
                        <Grid item xs={12} md={6}>
                          <Card>
                            <CardContent>
                              <PrintComponent head="Other Source" body={lead.otherSource} />
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent
                              head="Category"
                              body={lead?.category?.map((item) => item.name).join(', ')}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Company Name" body={lead.companyName} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Business Type" body={lead.businessType} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Title" body={lead.title} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <PrintComponent head="Client" body={lead.client} />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    <Card sx={{ p: 4 }}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Lead
                      </Typography>
                      <EditLeadForm lead={lead} editMode={editMode} setEditMode={setEditMode} refecth={getLead} />
                    </Card>
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            maxWidth: 'auto',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {!newModal && !existingModal && (
            <Stack>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Connect Client
              </Typography>
              <Stack mt={2} direction={'column'} gap={2}>
                {clientExists && (
                  <>
                    <Button variant="outlined" size="large" onClick={handleExistingModal}>
                      {' '}
                      Connect Existing
                    </Button>
                    <Divider>or</Divider>
                  </>
                )}
                <Button variant="contained" size="large" onClick={handleNewModal}>
                  Connect New
                </Button>
              </Stack>
            </Stack>
          )}

          {existingModal && (
            <Stack>
              <Stack alignItems={'center'} direction={'row'} gap={1}>
                <IconButton onClick={handleClickBack}>
                  <Iconify icon={'ep:back'} />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Existing Client
                </Typography>
              </Stack>
              <Stack mt={2} direction={'column'} gap={2}>
                <TextField
                  label="Email Id"
                  value={lead.email}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
          {newModal && (
            <Stack>
              <Stack alignItems={'center'} direction={'row'} gap={1}>
                <IconButton onClick={handleClickBack}>
                  <Iconify icon={'ep:back'} />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  New Client
                </Typography>
              </Stack>
              <Stack mt={2} direction={'column'} gap={2}>
                <TextField
                  label="Email Id"
                  value={clientEmail}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  focused
                  onChange={(e) => setClientEmail(e.target.value)}
                />
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Modal>
    </Page>
  );
}

export default ViewLead;
