import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from './Page';
import Logo from './Logo';
// sections
import AddUserForm from './AddUserForm';

// ----------------------------------------------------------------------




const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddUser({ closePopup, getUsers, options }) {

  return (
    <Container>
      <ContentStyle>
        <AddUserForm closePopup={closePopup} getUsers={getUsers} options={options} />
      </ContentStyle>
    </Container>
  );
}
