import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { BorderColor, Edit } from '@mui/icons-material';
import Page from '../components/Page';
import MemberCalendarCard from '../components/MemberCalendarCard';
import MemberTasksTable from '../components/MemberTasksTable';
import MemberProjectCount from '../components/MemberProjectCount';
import MemberNotes from '../components/MemberNotes';
import BreadCrumps from '../components/BreadCrumps';
import EditUserForm from '../components/EditUserForm';

import { boxStyle } from '../theme/commonStyles';


// ----------------------------------------------------------------------



const nameStyle = {
  color: '#000',
  fontFamily: 'Sora',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
};

const detailsStyle = {
  color: '#000',
  fontFamily: 'Sora',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
};

function MemberProfile() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState({});
  const [editMode, setEditMode] = useState(false);

  const getMember = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member`, {
        params: {
          id,
        },
      });
      setMember(res.data.data.member);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMember();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [editMode]);


  return (
    <Page title="Member">
      <Container maxWidth="xl">
        <Grid container spacing={5} rowGap={2}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid item xs={12}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  marginBottom: '-30px',
                }}
              >
                <BreadCrumps singlePage={'Profile'} />
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} md={7} lg={8}>
            <Box sx={{ ...boxStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {!editMode ? (
                <Stack
                  direction={{
                    xs: 'column',
                    md: 'row',
                  }}
                  justifyContent={{
                    xs: 'center',
                    md: 'space-between',
                  }}
                  sx={{
                    width: '100%',
                  }}
                  alignItems={'center'}
                >
                  <Avatar sx={{ width: 150, height: 150 }} src={member?.profilePic} />
                  <Stack direction={'column'} alignItems={'start'} spacing={2} justifyContent={'center'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                      <Typography sx={nameStyle}>{member.fullname}</Typography>
                      <IconButton onClick={() => setEditMode(!editMode)}>
                        <BorderColor />
                      </IconButton>
                    </Stack>
                    <Typography sx={detailsStyle}>{member.accType?.name}</Typography>
                    <Typography sx={detailsStyle}>{`ID: ${member._id}`}</Typography>
                    <Typography sx={detailsStyle}>{`Date Of Join: ${moment.utc(member.createdAt).format(
                      'DD/MM/YYYY'
                    )}`}</Typography>

                  </Stack>
                  <Divider orientation="vertical" flexItem sx={{ height: 'auto', border: '1px dashed #ccc' }} />
                  <Stack direction={'column'} alignItems={'start'} spacing={1} justifyContent={'center'}>
                    <Typography sx={detailsStyle}>{`Phone: ${member.mobile}`}</Typography>
                    <Typography sx={detailsStyle}>{`Email: ${member.email}`}</Typography>
                    <Typography sx={detailsStyle}>{`Gender: ${member.gender}`}</Typography>
                  </Stack>
                </Stack>
              ) : (
                <EditUserForm item={member} setEditMode={setEditMode} getMember={getMember} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Box sx={{ width: '100%' }} display={'flex'} justifyContent={'center'}>
              <MemberCalendarCard />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={boxStyle}>
              <MemberTasksTable />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={boxStyle}>
              <MemberProjectCount />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={boxStyle}>
              <MemberNotes />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default MemberProfile;
