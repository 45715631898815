import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  MenuItem,
  Typography,
  Avatar,
  styled,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from './Iconify';
import FileInputField from './FileInputField';

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function AddUserForm({ closePopup, getUsers, options }) {
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [roles, setRoles] = useState([]);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Full name required'),
    accType: Yup.string().required('Role required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    gender: Yup.string().oneOf(['MALE', 'FEMALE', 'OTHER'], 'Required').required('Required'),
    mobile: Yup.string().required('Mobile is required'),
    address: Yup.string().required('Address is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      accType: '',
      email: '',
      gender: '',
      mobile: '',
      password: '',
      profilePic: '',
      isBlocked: 'false',
      address: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/register`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (!response.data.isError) {
          toast.success(response.data.message);
          closePopup();
          getUsers(options);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || 'An error occurred');
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps, setFieldValue } = formik;

  const getRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/admin/all/list`, {
        params: { isBlocked: false },
      });
      if (!response.data.isError) {
        setRoles(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (values.profilePic) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setProfileImage(reader.result);
        }
      };
      reader.readAsDataURL(values.profilePic);
    }
  }, [values.profilePic]);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2} mt={1}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1.5, sm: 3 }}
              alignItems={{ xs: 'left', sm: 'center' }}
              sx={{ width: '100%' }}
            >
              <StyledLabelTypography title={'Photo'} />
              <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ width: '100%' }}>
                <Avatar alt="Remy Sharp" src={profileImage} sx={{ width: 56, height: 56 }} />
                <FileInputField
                  onChange={(e) => setFieldValue('profilePic', e.target.files[0])}
                  value={formik.values.profilePic}
                  accept=".jpg, .jpeg, .png"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Full name'} />
              <TextField
                size="small"
                fullWidth
                placeholder="Full name"
                {...getFieldProps('fullname')}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Role'} /> 
              <Autocomplete
                options={roles}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => setFieldValue('accType', value?._id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={Boolean(touched.accType && errors.accType)}
                    helperText={touched.accType && errors.accType}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} spacing={1}>
              <StyledLabelTypography title={'Gender'} />
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="gender"
                  {...getFieldProps('gender')}
                >
                  <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                  <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                  <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Email'} />
              <TextField
                size="small"
                fullWidth
                autoComplete="username"
                type="email"
                placeholder="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Mobile'} />
              <TextField
                size="small"
                fullWidth
                placeholder="mobile"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Address'} />
              <TextField
                size="small"
                fullWidth
                multiline
                rows={2}
                placeholder="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Password'} />
              <TextField
                size="small"
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Status'} />
              <TextField select placeholder="Status" size="small" name="isBlocked" {...getFieldProps('isBlocked')}>
                <MenuItem value={'false'}>Active</MenuItem>
                <MenuItem value={'true'}>Blocked</MenuItem>
              </TextField>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ fontFamily: 'Inter' }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
