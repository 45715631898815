import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  MenuItem,
  Typography,
  Avatar,
  styled,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function AddLeadForm({ closePopup }) {
  const [leadCategories, setLeadCategories] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().optional(),
    email: Yup.string().optional().email('Email must be a valid email'),
    phone: Yup.string().optional(),
    address: Yup.string().optional(),
    source: Yup.string().required('Source is required'),
    otherSource: Yup.string().optional(),
    category: Yup.array().required('Category is required').min(1, 'Category is required'),
    companyName: Yup.string().optional(),
    businessType: Yup.string().optional(),
    title: Yup.string().optional(),
    status: Yup.string().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '', 
      phone: '',
      address: '',
      source: '',
      otherSource: '',
      category: [],
      companyName: '',
      businessType: '',
      title: '',
      status: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log('clicked');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/lead/admin`, values);
        if (!response.data.isError) {
          toast.success(response.data.message);
          closePopup();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        return false;
      }
    },
  });

  const getLeadCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/admin/all/active`);
      setLeadCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadSources = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/admin/all/active`);
      setLeadSources(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadStatuses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/admin/all/list`);
      setLeadStatuses(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeadCategories();
    getLeadSources();
    getLeadStatuses();
  }, []);

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Full name"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadSources}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('source', value._id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Source"
                  error={Boolean(touched.source && errors.source)}
                  helperText={touched.source && errors.source}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>

          {values.source && values.source.toLowerCase() === 'other' && (
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                placeholder="Other Source"
                {...getFieldProps('otherSource')}
                error={Boolean(touched.otherSource && errors.otherSource)}
                helperText={touched.otherSource && errors.otherSource}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              multiple
              size="small"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadCategories}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue(
                    'category',
                    value.map((item) => item._id)
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Category"
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Company Name"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Business Type"
              {...getFieldProps('businessType')}
              error={Boolean(touched.businessType && errors.businessType)}
              helperText={touched.businessType && errors.businessType}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Title"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadStatuses}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('status', value._id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Status"
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>

        

          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ fontFamily: 'Inter' }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
