import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  MenuItem,
  Typography,
  Avatar,
  styled,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import { RemoveCircle } from '@mui/icons-material';
import { toast } from 'react-toastify';
import Iconify from './Iconify';
import FileInputField from './FileInputField';

// ----------------------------------------------------------------------

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function EditHeirarchyForm({ closePopup, getTaskLists, item }) {
  const [subTask, setSubTask] = useState({});
  const HeirarchySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    priority: Yup.number().required('Priority is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: item ? item._id : null,
      name: item ? item.name : '',
      priority: item ? item.priority : '',
      isBlocked: item ? item.isBlocked.toString() : 'false',
      subTask: item ? item.subTask : [],
    },
    validationSchema: HeirarchySchema,
    onSubmit: async () => {
      //  check if
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/taskList`, values, {});
        console.log(response);
        if (!response.data.isError) {
          toast.success(response.data.message);
          getTaskLists();
          closePopup();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (item) {
      formik.setValues({
        name: item.name,
        priority: item.priority,
        isBlocked: item.isBlocked.toString(),
        id: item._id,
        subTask: item.subTask,
      });
    }
  }, [item]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} rowGap={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Name"
                placeholder="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Priority"
                placeholder="Priority"
                type="number"
                {...getFieldProps('priority')}
                error={Boolean(touched.priority && errors.priority)}
                helperText={touched.priority && errors.priority}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                placeholder="Status"
                size="small"
                name="isBlocked"
                {...getFieldProps('isBlocked')}
                onChange={formik.handleChange}
              >
                <MenuItem value={'false'}>Active</MenuItem>
                <MenuItem value={'true'}>Blocked</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={'bold'}>Sub Tasks</Typography>
            </Grid>
            {values.subTask &&
              values.subTask.length > 0 &&
              values.subTask.map((subTask, index) => (
                <Grid item xs={12} key={index}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>{index + 1}.</Typography>
                    <TextField
                      size="small"
                      fullWidth
                      label=" Name"
                      placeholder=" Name"
                      {...getFieldProps(`subTask[${index}].name`)}
                    />
                    <TextField
                      size="small"
                      sx={{ width: '100px' }}
                      label=" Priority"
                      placeholder=" Priority"
                      value={subTask.priority}
                      type="number"
                      {...getFieldProps(`subTask[${index}].priority`)}
                    />
                    <IconButton>
                      <RemoveCircle
                        color="error"
                        onClick={() => {
                          const newSubTask = values.subTask.filter((subTask, i) => i !== index);
                          formik.setValues({ ...values, subTask: newSubTask });
                        }}
                      />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="end">
                <Button
                  variant="text"
                  startIcon={<Iconify icon="mdi:plus" color="primary" />}
                  onClick={() => {
                    const newSubTask = values.subTask;
                    newSubTask.push({ name: '', priority: '' });
                    formik.setValues({ ...values, subTask: newSubTask });
                  }}
                >
                  Add Sub Task
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
                <LoadingButton
                  size="small"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ fontFamily: 'Inter' }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
