import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import { useEffect, useState } from 'react';
import moment from 'moment';
import { Stack, TextField } from '@mui/material';
import { fCurrency, memorySize, fNumber } from '../utils/formatNumber';
import Chart, { useChart } from './chart2';

// ----------------------------------------------------------------------

export default function AppWebsiteVisits({ title, subheader, chart, api, graphTypeProp, graphTypeFixed = false, xaxis = 'datetime', yaxis = "amount", ...other }) {
    const { labels, colors, series, options } = chart;

    const formatNumber = (value) => {
        if (yaxis === "amount") {
            return fCurrency(value);
        } if (yaxis === "memory") {
            return memorySize(value);
        }
        return fNumber(value);
    };
    const chartOptions = useChart({
        colors,
        plotOptions: {
            bar: {
                columnWidth: '16%',
            },
        },
        fill: {
            type: series.map((i) => i.fill),
        },
        labels,
        xaxis: {
            type: xaxis,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value) => {
                    if (typeof value !== 'undefined') {
                        // return `₹ ${value.toFixed(2)} `;
                        return formatNumber(value);
                    }
                    return value;
                },
            },
        },
        ...options,
    });



    const [startDate, setStartDate] = useState(moment().subtract(7, "days").format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [graphType, setGraphType] = useState("DAY");

    useEffect(() => {
        api(startDate, endDate, graphType);
    }, [startDate, endDate]);

    useEffect(() => {
        setStartDate(moment().subtract(7, graphType).format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }, [graphType]);

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{ p: 3 }}>
                <TextField
                    size='small'
                    id="startDate"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    size='small'
                    id="endDate"
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {!graphTypeFixed && <TextField
                    id="graphType"
                    size='small'
                    select
                    label="Graph Type"
                    value={graphType}
                    onChange={(e) => setGraphType(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="DAY">Day</option>
                    <option value="WEEK">Week</option>
                    <option value="MONTH">Month</option>
                    <option value="YEAR">Year</option>
                </TextField>}
            </Stack>

            <Box sx={{ p: 3, pb: 1 }}>
                <Chart
                    dir="ltr"
                    type="line"
                    series={series}
                    options={chartOptions}
                    width="100%"
                    height={364}
                />
            </Box>
        </Card>
    );
}

AppWebsiteVisits.propTypes = {
    chart: PropTypes.object,
    subheader: PropTypes.string,
    title: PropTypes.string,
};
