import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

function ViewProfile({ admin }) {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Organization Profile</Typography>

      <Grid container spacing={2}>
        {[
          { label: 'Name', value: admin ? admin.fullname : 'loading', sm: 12, md: 6, lg: 6 },
          { label: 'Email', value: admin?.email, sm: 12, md: 6, lg: 6 },
          { label: 'Address', value: admin?.orgAddress?.address, sm: 12, md: 12, lg: 12 },
          { label: 'City', value: admin?.orgAddress?.city, sm: 6, md: 4, lg: 4 },
          { label: 'State', value: admin?.orgAddress?.state, md: 4 },
          { label: 'Country', value: admin?.orgAddress?.country, md: 4 },
          { label: 'Pin', value: admin?.orgAddress?.pin, md: 4 },
          { label: 'Member Since', value: moment(admin?.createdAt).format('MMM Do YY'), md: 4 },
          { label: 'Last Login', value: moment(admin?.lastLogin).format('MMM Do YY'), md: 4 },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={item.sm} md={item.md} lg={item.lg}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="body1">{item.label}:</Typography>
                <Typography variant="body1" fontWeight="600">
                  {item.value && item.value !== 'undefined' ? item.value : 'N/A'}
                </Typography>
              </Stack>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Stack>
  );
}

export default ViewProfile;
