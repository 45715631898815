import { Avatar, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import BreadCrumps from '../components/BreadCrumps';
import Page from '../components/Page';
import Attachments from '../components/Attachments';
import TaskFileUploadModal from '../components/TaskFileUploadModal';

function TaskAttachments() {
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams(); 
  const [names, setNames] = React.useState({});
  const [attachments, setAttachments] = React.useState([]);

  const getNames = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/admin/names`, {
        params: { 
          id,
        },
      });
      console.log(res);
      if (!res.data.isError) {
        setNames(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAttachments = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/admin/attachments`, {
        params: {
          id,
        },
      });
      console.log(res);
      setIsLoading(false);
      if (!res.data.isError) {
        setAttachments(res.data.data.attachments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getNames();
    getAttachments();
  }, []);

  return (
    <Page title="Attachments">
      {isLoading ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={5} rowGap={2}>
            <Grid item xs={12}>
              <BreadCrumps
                project={{
                  name: names?.projectName,
                  id: names?.projectId,
                }}
                task={{
                  name: names?.taskName,
                  id: names?.taskId,
                }}
                category={{
                  name: names?.category,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal', 
                    letterSpacing: '-0.08px',
                  }}
                >
                  {attachments && attachments.length ? 'Attachments' : 'No Attachments'}
                </Typography>
                {/* <TaskFileUploadModal getAttachments={getAttachments} projectId={names?.projectId} /> */}
              </Stack>
              {!!attachments?.length && <Attachments attachments={attachments} />}
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default TaskAttachments;
