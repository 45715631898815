import React, { useState } from 'react';
import { Tabs, Tab, Typography, Container, Stack } from '@mui/material';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import ProjectCategories from '../components/ProjectCategories';
import TaskList from './TaskList';

function ProjectSettings() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Project Settings">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <PageHeader title="Project Settings" />
        </Stack>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Project Categories" />
          <Tab label="Task Heirarchy" />
        </Tabs>
        {value === 0 && <ProjectCategories />}
        {value === 1 && <TaskList />}
      </Container>
    </Page>
  );
}

export default ProjectSettings;
