import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


function CategoryCard({ category }) {
  const navigate = useNavigate();

  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={1}
      sx={{
        width: '100%',
        height: '150px',
        borderRadius: '8px',
        background: '#fff',
        px: '8px',
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(`/dashboard/project?category=${category?.category}`);
      }}
    >
      <Box
        sx={{
          width: '44px',
          height: '44px',
          borderRadius: '50%',
          background: 'linear-gradient(150deg, #09D 15.58%, #0054A6 88.53%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component={'img'} src={category?.categoryIcon} sx={{ width: '24px', height: '24px' }} />
      </Box>

      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          color: '#0B4DA2',
          fontFamily: 'Sora, sans-serif',
        }}
      >
        {category?.totalProjects}
      </Typography>
      <Box
        sx={{ 
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
        }}
      >
        <Typography
          textAlign={'center'}
          sx={{ 
            fontSize: '16px',
            fontWeight: 600,
            color: '#242331cc',
            fontFamily: 'Sora, sans-serif',
            lineHeight: '16px',
          }}
        >
          {category?.category && category?.category?.replaceAll('_', ' ')}
        </Typography>
      </Box>
    </Stack>
  );
}

export default CategoryCard;
