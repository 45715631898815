import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Grid, TextField, Typography, MenuItem, Stack } from '@mui/material';
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';

const InstructorSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    email: Yup.string().email().required('Email is required'),
    profilePic: Yup.string().optional(),
    logo: Yup.string().optional(),
});

function EditProfile({ admin, onFetchAdmin }) {

    const [addressFormData, setAddressFormData] = useState({
        address: '',
        city: '',
        state: '',
        pin: '',
        country: '',
        phone1: '',
        phone2: '',
    });

    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        setContactFormData({
            name: admin?.contactPerson?.name,
            email: admin?.contactPerson?.email,
            phone: admin?.contactPerson?.phone,
        })
    }, [])


    useEffect(() => {
        setAddressFormData({
            address: admin?.orgAddress?.address,
            city: admin?.orgAddress?.city,
            state: admin?.orgAddress?.state,
            pin: admin?.orgAddress?.pin,
            country: admin?.orgAddress?.country,
            phone1: admin?.orgAddress?.phone1,
            phone2: admin?.orgAddress?.phone2,
        })
    }, []);

    const fetchAdmin = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/profile`);
            formik.setValues((values) => ({
                ...values,
                fullname: res.data.data.fullname,
                email: res.data.data.email,
                futurePlanPrice: res.data.data.futurePlanPrice,
                futurePlanStartDate: res.data.data.futurePlanStartDate,
                contactPerson: res.data.data.contactPerson,
            }));
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchAdmin();
    }, []);

    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            isSuspended: false,
            contactPerson: {},
            profilePic: "",
            orgAddress: {},
            logo: ""
        },
        validationSchema: InstructorSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", admin?._id);
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            Object.keys(addressFormData).forEach((key) => {
                formData.append(`orgAddress[${key}]`, addressFormData[key]);
            });
            Object.keys(contactFormData).forEach((key) => {
                formData.append(`contactPerson[${key}]`, contactFormData[key]);
            });
            try {
                const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin/profile`, formData);
                if (res.data.isError) {
                    toast.error(res.data.message);
                } else {
                    toast.success(res.data.message);
                    onFetchAdmin();
                }
            } catch (e) {
                toast.error(e.response.data.message);
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue } = formik;



    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressFormData({
            ...addressFormData,
            [name]: value,
        });
    };
    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactFormData({
            ...contactFormData,
            [name]: value,
        });
    };

    return (
        <Stack gap={3}>
            <Typography variant='h6'>Edit Profile</Typography>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size='small'
                                label="Full Name"
                                {...getFieldProps("fullname")}
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.fullname && Boolean(errors.fullname)}
                                helperText={touched.fullname && errors.fullname}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size='small'
                                label="Email"
                                {...getFieldProps("email")}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>



                        {Object.keys(addressFormData).map((field, index) => (
                            <Grid item xs={12} sm={field === "address" ? 12 : 3} key={index}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                                    name={field}
                                    value={addressFormData[field]}
                                    onChange={handleAddressChange}
                                    variant="outlined"
                                />
                            </Grid>
                        ))}


                        {Object.keys(contactFormData).map((field, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={`Contact Person  ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                    name={field}
                                    value={contactFormData[field]}
                                    onChange={handleContactChange}
                                    variant="outlined"
                                />
                            </Grid>
                        ))}


                        <Grid item xs={12} sm={6}>
                            <TextField
                                type='file'
                                fullWidth
                                label="Profile Pic"
                                size='small'
                                onChange={(e) => setFieldValue("profilePic", e.target.files[0])}
                                onBlur={handleBlur}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                error={touched.profilePic && Boolean(errors.profilePic)}
                                helperText={touched.profilePic && errors.profilePic}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type='file'
                                fullWidth
                                label="Logo"
                                size='small'
                                onChange={(e) => setFieldValue("logo", e.target.files[0])}
                                onBlur={handleBlur}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                error={touched.logo && Boolean(errors.logo)}
                                helperText={touched.logo && errors.logo}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant='contained' sx={{ backgroundColor: "gray", ":hover": { backgroundColor: "#424242" } }} onClick={fetchAdmin}>Cancel</Button>
                        <Button variant='contained' type='submit'>Save Changes</Button>
                    </Stack>
                </Form>
            </FormikProvider>
        </Stack>
    )
}

export default EditProfile;
