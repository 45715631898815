import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Container, Stack } from '@mui/material';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import MemberRoles from '../components/MemberRoless';

function GeneralSettings() {
  const [value, setValue] = useState('memberRoles'); // Default to 'categories'
  const [searchParams, setSearchParams] = useSearchParams();

  const tabValue = searchParams.get('tab');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    } else {
      setValue('memberRoles');
      setSearchParams({ tab: 'memberRoles' });
    }
  }, [tabValue, setSearchParams]);

  return (
    <Page title="General Settings">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <PageHeader title="General Settings" />
        </Stack>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="User Roles" value="memberRoles" />
        </Tabs>
        {value === 'memberRoles' && <MemberRoles />}
      </Container>
    </Page>
  );
}

export default GeneralSettings;
