import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider } from '@mui/material';
// mock
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { LogoOnly } from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { topConfig, bottomConfig } from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
  },
}));


// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { isLoading, isAuth, user, error } = useSelector((state) => state.login);
  const { isCollapsed } = useSelector((state) => state.sidebarCollapse);

  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        bgcolor: '#fff',
      }}
    >
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: 5 }} spacing={3}>
        <LogoOnly width={isCollapsed ? 30 : 50} />
        <Typography
          textAlign={'center'}
          sx={{
            fontFamily: 'Sora',
            fontWeight: 600,
            lineHeight: '25px',
            color: '#0B132B',
            opacity: isCollapsed ? 0 : 1,
            transition: 'all .3s ease-in-out',
          }}
          variant="headMain"
        >
          StackArch
        </Typography>
      </Stack>


      <NavSection navConfig={topConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Divider
        sx={{
          margin: 2,
          borderColor: 'divider ',
        }}
      />
      <NavSection navConfig={bottomConfig} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: { lg: isCollapsed ? 80 : 240 },
        transition: 'all .3s ease-in-out',
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: isCollapsed ? 240 : 80, transition: 'all .3s ease-in-out' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isCollapsed ? 80 : 240,
              bgcolor: 'background.default',
              border: 'none',
              transition: 'all .3s ease-in-out',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
