import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountCircle, BorderColorOutlined, CameraAlt, Email, Key } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../components/Page';
import BackIcon from '../assets/icons/back.png';
import { loginSuccess } from '../sections/auth/login/LoginSlice';

import { boxStyle } from '../theme/commonStyles';
import Details from '../components/orgProfile/Details';


function InputComponent({ label, placeholder, icon, disabled, value, setValue, type }) {
  const commonTextFieldStyles = {
    width: '100%',
    color: '#2E2E2E',
    fontFamily: 'Work Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };

  const inputAdornmentStyles = {
    color: '#1F0B61',
  };

  return (
    <Stack spacing={0.5}>
      <Typography
        sx={{
          color: '#6871A3',
          fontFamily: 'Sora',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
      >
        {label}
      </Typography>
      <TextField
        type={type || 'text'}
        sx={{
          ...commonTextFieldStyles, // Apply common styles
          ...(disabled && { backgroundColor: 'transparent' }), // Add transparent background for disabled state
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={inputAdornmentStyles}>
              {icon}
            </InputAdornment>
          ),
        }}
        fullWidth
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          if (disabled) return;
          setValue(e.target.value);
        }}
      />
    </Stack>
  );
}

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [admin, setAdmin] = useState({});
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const getAdmin = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/profile`);
      console.log(res.data);
      setAdmin(res.data.data);
      setFullname(res.data.data.fullname);
      setEmail(res.data.data.email);
      setPassword('');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateAdmin = async () => {
    try {
      const formData = new FormData();
      formData.append('fullname', fullname);
      formData.append('email', email);
      formData.append('password', password);
      if (profilePic) {
        formData.append('profilePic', profilePic);
      }
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin/profile`, formData);
      console.log(res.data);
      toast.success(res.data.message);
      localStorage.setItem('profile', JSON.stringify(res.data.data));
      dispatch(loginSuccess(res.data.data));
      setEditMode(false);
      getAdmin();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <Page title="Profile">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={5} rowGap={3}>
            <Grid item xs={12}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  marginBottom: '-30px',
                }}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Box
                    onClick={() => navigate(-1)}
                    component={'img'}
                    src={BackIcon}
                    sx={{
                      width: '32px',
                      height: '32px',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#0B132B',
                      fontFamily: 'Sora',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      letterSpacing: '-0.11px',
                    }}
                  >
                    Profile
                  </Typography>
                </Stack>
              </Stack>
            </Grid>



            <Grid item xs={12} sm={6} md={4}>
              <Box sx={boxStyle}>
                <Stack direction={'column'} alignItems={'center'} spacing={1}>


                  <Avatar
                    sx={{
                      width: { xs: 150, sm: 180, md: 200, lg: 220 },
                      height: { xs: 150, sm: 180, md: 200, lg: 220 },
                    }}
                    src={profilePicPreview || admin.profilePic || ''}
                  />

                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography
                      sx={{
                        color: '#2E2E2E',
                        fontFamily: 'Sora',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        letterSpacing: '-0.09px',
                      }}
                    >
                      {admin.fullname}
                    </Typography>

                  </Stack>
                  <Typography
                    sx={{
                      color: '#2E2E2E',
                      fontFamily: 'Sora',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.08px',
                    }}
                  >
                    {admin.email}
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={8} lg={8} >
              <Details admin={admin} onFetchAdmin={getAdmin} />
              {/* <Box sx={boxStyle}>
                <Stack direction={'column'} spacing={3}>
                  <InputComponent
                    label="Fullname"
                    placeholder="John Doe"
                    icon={<AccountCircle sx={{ color: '#1F0B61' }} />}
                    disabled={!editMode}
                    value={fullname}
                    setValue={setFullname}
                  />
                  <InputComponent
                    label="Email"
                    placeholder="Email"
                    icon={<Email sx={{ color: '#1F0B61' }} />}
                    disabled={!editMode}
                    value={email}
                    setValue={setEmail}
                  />
                  <InputComponent
                    label="Password"
                    placeholder="Password"
                    icon={<Key sx={{ color: '#1F0B61' }} disabled={!editMode} />}
                    disabled={!editMode}
                    value={password}
                    setValue={setPassword}
                    type="password"
                  />
                  {editMode && (
                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
                      <Button
                        variant="outlined"
                        color="buttonone"
                        onClick={() => {
                          setEditMode(false);
                          getAdmin();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={updateAdmin}>
                        Save
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default Profile;
