import { Typography } from '@mui/material';
import React from 'react';

function PageHeader({ title }) {
  return (
    <Typography
      sx={{
        color: '#0B132B',
        lineHeight: 'normal',
        letterSpacing: '-0.1px',
      }}
      variant="headMain"
      component={'p'}
    >
      {title}
    </Typography>
  );
}

export default PageHeader;
