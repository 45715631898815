import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Project from './pages/Projects';
import NewProject from './pages/NewProject';
import { ViewProject } from './pages/ViewProject';
import { CreateTask } from './pages/CreateTask';
import { ViewTask } from './pages/ViewTask';
import { SubTaskView } from './pages/SubTaskView';
import { CreateTaskFromTask } from './pages/CreateTaskFromTask';
import Billing from './pages/Billing';
import ProjectAnalytics from './pages/ProjectAnalytics';
import CategoryAnalytics from './pages/CategoryAnalytics';
import Profile from './pages/Profile';
import MemberProfile from './pages/MemberProfile';
import TaskCheckListView from './pages/TaskCheckListView';
import TaskAttachments from './pages/TaskAttachments';
import Client from './pages/Client';
import ToDo from './pages/ToDo';
import TaskList from './pages/TaskList';
import Admin from './pages/Admin';
import GeneralSettings from './pages/GeneralSettings';
import Lead from './pages/Lead';
import CrmSettings from './pages/CrmSettings';
import ProjectSettings from './pages/ProjectSettings';
import ViewLead from './pages/ViewLead';
import Attendance from './pages/Attendance';
import Leave from './pages/Leave';
import WrokLog from './pages/WrokLog';
import WorkReport from './pages/WorkReport';
import Invoices from './pages/Invoices';
import InvoiceView from './pages/InvoiceView';
import Customer from './pages/Customer';
import CustomerView from './pages/CustomerView';
import VerifySignup from './pages/VerifySignup';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuth } = useSelector((state) => state.login);
  const accessToken = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: accessToken ? <DashboardLayout /> : <Navigate to="/login" />,
      // element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'general/employee', element: <User /> },
        { path: 'general/client', element: <Customer /> },
        { path: 'general/client/view/:id', element: <CustomerView /> },
        { path: 'general/settings', element: <GeneralSettings /> },
        { path: 'crm/lead', element: <Lead /> },
        { path: 'crm/lead/view/:id', element: <ViewLead /> },
        { path: 'crm/settings', element: <CrmSettings /> },
        { path: 'hr/attendance', element: <Attendance /> },
        { path: 'hr/work-log', element: <WrokLog /> },
        { path: 'hr/work-log/work-report', element: <WorkReport /> },
        { path: 'hr/leave', element: <Leave /> },
        { path: 'project/to-do', element: <ToDo /> },
        { path: 'project/settings', element: <ProjectSettings /> },
        { path: 'project/projects', element: <Project /> },
        { path: 'project/projects/newProject', element: <NewProject /> },
        { path: 'project/projects/:id', element: <ViewProject /> },
        { path: 'project/projects/create/:id', element: <CreateTask /> },
        { path: 'project/projects/task', element: <SubTaskView /> },
        { path: 'project/projects/task/attachments/:id', element: <TaskAttachments /> },
        { path: 'project/projects/task/create', element: <CreateTaskFromTask /> },
        { path: 'project/projects/task/view', element: <ViewTask /> },
        { path: 'project/projects/task/subtask/view', element: <TaskCheckListView /> },
        { path: 'billing/:id', element: <Billing /> },
        { path: 'project-analytics/:id', element: <ProjectAnalytics /> },
        { path: 'category-analytics', element: <CategoryAnalytics /> },
        { path: 'profile', element: <Profile /> },
        { path: 'member/:id', element: <MemberProfile /> },
        { path: 'taskList', element: <TaskList /> },
        { path: 'admin', element: <Admin /> },
        {
          path: 'invoice',
          children: [
            { path: "", element: <Invoices /> },
            { path: 'view', element: <InvoiceView />, }
          ]
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        // { path: 'login', element: <Login /> },
        { path: 'login', element: accessToken ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'verify-email', element: <VerifySignup /> },
        { path: '404', element: <NotFound /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },

  ]);
}
