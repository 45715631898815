import * as React from 'react';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { TextField, Button, Stack, Box, MenuItem, Collapse, Grid } from '@mui/material';

const Filter = ({ open, filterValues, applyFilters }) => (
  <Collapse in={open}>
    <Formik
      initialValues={{
        fullname: filterValues.fullname,
        email: filterValues.email,
        isBlocked: filterValues.isBlocked,
      }}
      onSubmit={async (values) => {
        applyFilters(values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="fullname"
                name="fullname"
                label="Name"
                value={values.fullname}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Status"
                value={values.isBlocked}
                name="isBlocked"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.isBlocked && errors.isBlocked)}
                helperText={touched.isBlocked && errors.isBlocked}
              >
                <MenuItem value="false">Active</MenuItem>
                <MenuItem value="true">Blocked</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit" style={{ marginTop: '10px' }} color="buttonone">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Collapse>
);

export default Filter;
