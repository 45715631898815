import { Avatar, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

function Notes({ comments, userData }) {
  return (
    <Stack
      direction={'column-reverse'}
      spacing={5}
      sx={{
        borderRadius: '6px',
        width: '100%',
      }}
    >
      {comments?.map((comment, index) => (
        <Stack
          key={index}
          sx={{
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={comment?.isAdmin ? 'Admin' : userData?.fullname}
              src={comment?.user?.avatarUrl}
            >
              {comment?.isAdmin ? 'A' : userData?.fullname?.charAt(0)}
            </Avatar>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography
                  sx={{
                    color: '#0B132B',
                    fontFamily: 'Sora',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.11px',
                  }}
                >
                  {/* {comment?.user?.name} */}
                  {comment?.isAdmin ? 'Admin' : userData?.fullname}
                </Typography>
                <Typography
                  sx={{
                    color: '#4F4F4F',
                    fontFamily: 'Sora',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '-0.11px',
                  }}
                >
                  {moment.utc(comment?.createdAt).format('MMMM Do, YYYY')}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: '#0B132B',
                  fontFamily: 'Sora',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '-0.11px',
                }}
              >
                {comment?.comment}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default Notes;
