import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import LogoImg from '../assets/logo.png';
import LogoOnlyImg from '../assets/logo_only.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: { xs: 200, sm: 200, md: 200 }, margin: '0 auto', marginTop: { sm: '50px' }, ...sx }}>
      <img src={LogoImg} alt="logo" style={{ height: '100%', width: '100%' }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export const LogoOnly = ({ width }) => (
  <Box sx={{ width: width || 50, margin: '0 auto', marginTop: { sm: '50px' }, transition: 'width 0.5s' }}>
    <img src={LogoOnlyImg} alt="logo" style={{ height: '100%', width: '100%' }} />
  </Box>
);
