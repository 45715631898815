import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Stack,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Zoom,
  Autocomplete,
  FormControl,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Select,
  CardMedia,
} from '@mui/material';
import { toast } from 'react-toastify';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';
import Moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Form, useFormik, FormikProvider } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { PieChart, ReceiptLong, Today, InsertInvitation, MoreVert, Edit } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChangeManagerPopup from '../components/ChangeManagerPopup';
import BackIcon from '../assets/icons/back.png';
import Page from '../components/Page';
import { ListTask } from './ListTask';
import Iconify from '../components/Iconify';
import BreadCrumps from '../components/BreadCrumps';

const headingStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '-0.09px',
};

const contentStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '-0.08px',
};

const memberHeadingStyle = {
  color: 'rgba(46, 46, 46, 0.80)',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '-0.08px',
};

export const ViewProject = () => {
  const navigate = useNavigate();
  const [getProject, setGetProject] = useState({});
  const [nonmembers, setNonmembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [projectImage, setProjectImage] = useState(null);
  const [updateProjectLoading, setUpdateProjectLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [loadingInviteClient, setLoadingInviteClient] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };

  const formik = useFormik({
    initialValues: {
      userIds: [],
      projectId: id,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/project/addmembers`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const getProjectById = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/`, { params: { projectId: id } });
      console.log('res', res.data.data.project);
      setGetProject(res.data.data.project);
      setDescription(res.data.data.project.description);
      setStartDate(res.data.data.project.startDate);
      setEndDate(res.data.data.project.endDate);
      setCategory(res.data.data.project.category);
      setLocation(res.data.data.project.location);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getCategories = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/category`).then((res) => {
        setCategories(res.data.data.categories);
        console.log('All CATEGORIES', res.data.data.categories);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getNonmembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/nonmembers`, { params: { projectId: id } });
      console.log('res', res);
      setNonmembers(res.data.data.data.nonmembers);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getClients = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/orgCustomer/admin/all/list`, {
        params: { projectId: id },
      });
      console.log('res', res);
      setClients(res.data.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleDialog = () => {
    setOpen1(true);
  };

  const handleClose = (e, flag = '') => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    if (flag) {
      callApis();
    }
  };

  const handleAddClientPopup = () => {
    setOpenAddClient(true);
  };

  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };

  const handleMnagerPopup = () => {
    setOpen3(true);
    setAnchorEl1(null);
  };

  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/removemember`, {
        userId: open2.id,
        projectId: id,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };

  const updateProject = async () => {
    setUpdateProjectLoading(true);
    const data = {
      projectId: id,
      description,
      startDate,
      endDate,
      category,
      location,
    };
    if (projectImage) {
      data.projectImage = projectImage;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Project  Successfully Updated');
      getProjectById();
      setIsLoading(false);
      setUpdateProjectLoading(false);
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const inviteClient = async () => {
    try {
      setLoadingInviteClient(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/project/invite-client`, {
        projectId: id,
        clientId: selectedClient,
      });
      setLoadingInviteClient(false);
      toast.success(res.data.message);
      setOpenAddClient(false);
      getProjectById();
    } catch (error) {
      setLoadingInviteClient(false);
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  const handleCloseClientInvite = () => {
    setOpenAddClient(false);
    setLoadingInviteClient(false);
  };

  const callApis = async () => {
    setIsLoading(true);
    await getProjectById();
    await getNonmembers();
    await getClients();
    setIsLoading(false);
  };

  useEffect(() => {
    callApis();
    getCategories();
  }, []);

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values } = formik;

  return (
    <Page title="Dashboard : Project ">
      <Container maxWidth="xl">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* delete popup */}
            <Dialog
              open={open2.flag}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to remove this member from Project?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={removeMember} variant="contained" color="buttonone" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            {/* delete popup ends */}
            {/* Add member popup */}
            <Dialog
              open={open1}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  {'Add Members'}
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <Stack direction="column">
                      <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          name="userIds"
                          options={nonmembers}
                          getOptionLabel={(option) => option.fullname}
                          onChange={(e, value) => {
                            const temp = value.map((item, index) => item._id);
                            setFieldValue('userIds', temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select members"
                              placeholder="Members"
                              fullWidth
                              onChange={handleChange}
                              error={Boolean(touched.userIds && errors.userIds)}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar sx={{ width: 40, height: 40, cursor: 'pointer' }} src={option?.profilePic}>
                                  {option.fullname.charAt(0).toUpperCase()}
                                </Avatar>
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                    {option.email}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      </FormControl>
                      <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                        <Button variant="contained" type="submit" color="buttonone">
                          Add
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Dialog>
            {/* Add member popup ends */}
            {/* Change manager popup */}
            <ChangeManagerPopup popup={open3} handleClose={handleClose} />
            {/* Change manager popup ends */}
            {/* Add client popup */}
            <Dialog
              open={openAddClient}
              onClose={handleCloseClientInvite}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  {'Add Clients'}
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleCloseClientInvite} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack> 
              </DialogTitle>
              <DialogContent>
                <Stack direction="column">
                  <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                    <Autocomplete
                      id="multiple-limit-tags"
                      name="clientId"
                      options={clients}
                      getOptionLabel={(option) => option.clientId.fullname}
                      onChange={(e, value) => {
                        console.log('value', value);
                        if (value) {
                          setSelectedClient(value?.clientId?._id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select clients" placeholder="Clients" fullWidth />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar sx={{ width: 40, height: 40, cursor: 'pointer' }} src={option?.profilePic}>
                              {option?.clientId?.fullname.charAt(0).toUpperCase()}
                            </Avatar>
                            <Stack direction={'column'}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                {option?.clientId?.fullname}
                              </Typography>
                              <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                {option?.clientId?.email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </li>
                      )}
                    />
                  </FormControl>
                  <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      color="buttonone"
                      disabled={!selectedClient}
                      loading={loadingInviteClient}
                      onClick={inviteClient}
                    >
                      Add
                    </LoadingButton>
                  </Stack>
                </Stack>
              </DialogContent>
            </Dialog>
            {/* Add client popup ends */}

            <Grid container spacing={5} rowGap={3}>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={2}
                  sx={{
                    marginBottom: '-30px',
                  }}
                >
                  <BreadCrumps
                    project={{
                      name: getProject?.name,
                      id: getProject?._id,
                    }}
                  />

                  <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Button
                      startIcon={<PieChart />}
                      variant="outlined"
                      color="buttonone"
                      onClick={() => navigate(`/dashboard/project-analytics/${id}`)}
                      sx={{
                        borderWidth: '2px',
                        ':hover': {
                          borderWidth: '2px',
                        },
                        fontFamily: 'Sora',
                      }}
                    >
                      Analytics
                    </Button>
                    <Button
                      startIcon={<ReceiptLong />}
                      variant="contained"
                      color="buttonone"
                      onClick={() => navigate(`/dashboard/billing/${id}`)}
                      sx={{ fontFamily: 'Sora' }}
                    >
                      Billing
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Box
                  sx={{
                    bgcolor: '#fff',
                    minHeight: '520px',
                    borderRadius: '12px',
                    height: '100%',
                  }}
                  padding={4}
                >
                  <Stack direction="column" sx={{ width: '100%' }} spacing={4}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <Today sx={{ color: '#63A3C0' }} />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Start Date</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>
                              {getProject?.startDate ? Moment.utc(getProject?.startDate).format('YYYY-MM-DD') : ' '}
                            </Typography>
                          ) : (
                            <TextField
                              sx={{ width: '145px' }}
                              type="date"
                              size="small"
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                              value={startDate ? Moment.utc(startDate).format('YYYY-MM-DD') : ''}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={5}>
                        <Stack direction="row" spacing={0.5}>
                          <InsertInvitation sx={{ color: '#63A3C0' }} />
                          <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                            <Typography sx={headingStyle}>End Date</Typography>
                            {!editMode ? (
                              <Typography sx={contentStyle}>
                                {getProject?.endDate ? Moment.utc(getProject?.endDate).format('YYYY-MM-DD') : ' '}
                              </Typography>
                            ) : (
                              <TextField
                                sx={{ width: '145px' }}
                                type="date"
                                size="small"
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                value={endDate ? Moment.utc(endDate).format('YYYY-MM-DD') : ''}
                              />
                            )}
                          </Stack>
                        </Stack>
                        <MoreVert
                          sx={{
                            color: '#1C1B1F',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                          }}
                          onClick={handleMenuOpen}
                        />
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() => {
                              setEditMode(true);
                              setAnchorEl(null);
                            }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Edit />
                              <Typography sx={contentStyle}>Edit</Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <CategoryIcon
                          sx={{
                            color: '#63A3C0',
                          }}
                        />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Category</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>{getProject?.category?.name}</Typography>
                          ) : (
                            <Autocomplete
                              multiple={false}
                              id="multiple-checkbox"
                              size="small"
                              fullWidth
                              sx={{
                                width: '220px',
                              }}
                              options={categories}
                              value={categories.find((option) => option._id === getProject.category._id)}
                              getOptionLabel={(option) => option.name}
                              onChange={(e, value) => {
                                setFieldValue('category', value._id);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="outlined"
                                  placeholder="Category"
                                  onChange={handleChange}
                                  error={Boolean(touched.category && errors.category)}
                                />
                              )}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <LocationOnIcon sx={{ color: '#63A3C0' }} />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Location</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>{getProject.location}</Typography>
                          ) : (
                            <TextField
                              size="small"
                              onChange={(e) => setLocation(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                              value={location}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="column" sx={{ width: '100%' }} spacing={2} pt={1}>
                      <Typography sx={{ ...headingStyle, fontSize: '16px' }}>Description</Typography>
                      {!editMode ? (
                        <Box
                          sx={{
                            width: '100%',
                            borderRadius: '6px',
                            // background: '#F5F5F5',
                          }}
                          padding={1.5}
                        >
                          <Typography
                            sx={{
                              color: '#414141',
                              fontFamily: 'Work Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              letterSpacing: '-0.014px',
                            }}
                          >
                            {getProject.description}
                          </Typography>
                        </Box>
                      ) : (
                        <TextField
                          multiline
                          rows={5}
                          maxRows={10}
                          size="small"
                          fullWidth
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      )}
                    </Stack>
                    {!editMode && getProject?.projectImage && (
                      <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
                        <CardMedia component="img" image={getProject.projectImage} alt="project image" />
                      </Stack>
                    )}
                    {editMode && (
                      <TextField
                        type="file"
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Project Image"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setProjectImage(e.target.files[0])}
                      />
                    )}
                    {editMode && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ width: '100%' }}
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          color="buttonone"
                          onClick={() => setEditMode(false)}
                          sx={{
                            borderWidth: '2px',
                            ':hover': {
                              borderWidth: '2px',
                            },
                            fontFamily: 'Sora',
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          variant="contained"
                          loading={updateProjectLoading}
                          color="buttonone"
                          onClick={updateProject}
                          sx={{
                            borderWidth: '2px',
                            ':hover': {
                              borderWidth: '2px',
                            },
                            fontFamily: 'Sora',
                          }}
                        >
                          Update
                        </LoadingButton>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Box
                  sx={{
                    bgcolor: '#fff',
                    borderRadius: '12px',
                  }}
                  padding={4}
                >
                  <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Typography sx={{ ...headingStyle, color: '#0096DB' }}>Project Team</Typography>
                      <MoreVert
                        sx={{
                          color: '#1C1B1F',
                          marginLeft: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={handleMenuOpen1}
                      />
                      <Menu id="simple-menu" anchorEl={anchorEl1} open={Boolean(anchorEl1)} onClose={handleMenuClose1}>
                        <MenuItem onClick={handleMnagerPopup}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Edit />
                            <Typography sx={contentStyle}>Change Manager</Typography>
                          </Stack>
                        </MenuItem>
                      </Menu>
                    </Stack>
                    <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                      <Typography sx={memberHeadingStyle}>Manager</Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/dashboard/member/${getProject?.manager._id}`)}
                      >
                        <Tooltip TransitionComponent={Zoom} title={getProject?.manager.email}>
                          <Avatar alt={getProject?.manager.fullname} src={getProject?.manager?.profilePic}>
                            {getProject?.manager?.fullname[0]}
                          </Avatar>
                        </Tooltip>
                        <Typography sx={contentStyle}>{getProject?.manager.fullname}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ width: '100%' }}
                      >
                        <Typography sx={memberHeadingStyle}>Members</Typography>
                        <Button
                          color="buttonone"
                          startIcon={<AddCircleOutlineSharpIcon />}
                          sx={{ fontFamily: 'Sora' }}
                          onClick={handleDialog}
                        >
                          Add
                        </Button>
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={1.5}
                        sx={{ width: '100%', maxHeight: '250px', overflowY: 'scroll' }}
                        className="scrollCard"
                      >
                        {getProject?.members.map((mdata, id) => (
                          <Stack
                            direction={'row'}
                            key={id}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{
                              width: '100%',
                              background: '#fff',
                              ':hover': {
                                background: '#f8f8f8',
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/dashboard/member/${mdata._id}`)}
                            >
                              {/* <Avatar alt={mdata.fullname} src={mdata.avatar} /> */}
                              <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                <Avatar alt={mdata.fullname} src={mdata.profilePic}>
                                  {mdata.fullname.charAt(0).toUpperCase()}
                                </Avatar>
                              </Tooltip>
                              <Typography sx={contentStyle}>{mdata.fullname}</Typography>
                            </Stack>
                            <IconButton onClick={() => handleDeletePopup(mdata._id)} color="buttonone">
                              <Iconify icon="material-symbols:delete" />
                            </IconButton>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                    {/*  clients */}
                    <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ width: '100%' }}
                      >
                        <Typography sx={memberHeadingStyle}>Clients</Typography>
                        <Button
                          color="buttonone"
                          startIcon={<AddCircleOutlineSharpIcon />}
                          sx={{ fontFamily: 'Sora' }}
                          onClick={handleAddClientPopup}
                        >
                          Add
                        </Button>
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={1.5}
                        sx={{ width: '100%', maxHeight: '250px', overflowY: 'scroll' }}
                        className="scrollCard"
                      >
                        {getProject?.clients.map((mdata, id) => (
                          <Stack
                            direction={'row'}
                            key={id}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{
                              width: '100%',
                              background: '#fff',
                              ':hover': {
                                background: '#f8f8f8',
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/dashboard/member/${mdata._id}`)}
                            >  
                              <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                <Avatar alt={mdata.fullname} src={mdata.profilePic}>
                                  {mdata.fullname.charAt(0).toUpperCase()}
                                </Avatar>
                              </Tooltip>
                              <Typography sx={contentStyle}>{mdata.fullname}</Typography>
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={3} marginTop={4}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#0B132B',
                      fontFamily: 'Sora',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      letterSpacing: '-0.11px',
                    }}
                  >
                    Tasks
                  </Typography>
                  <Button
                    sx={{ fontFamily: 'Sora' }}
                    variant="contained"
                    startIcon={<AddCircleOutlineSharpIcon />}
                    onClick={() => navigate(`/dashboard/project/projects/create/${id}`)}
                    color="buttonone"
                  >
                    Task
                  </Button>
                </Stack>
              </Grid>

              <ListTask />
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
};
