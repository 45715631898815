import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import BarAndLineGraph from '../graphs/BarAndLineGraph';

function MemberProjectCount() {
  const { id } = useParams(); 
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const getData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/member-count`, {
        params: {
          userId: id,
        },
      });
      console.log(res.data.data);
      setData(res.data.data); 
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <BarAndLineGraph title={'CATEGORIES'} chartData={data?.chartData} chartLabels={data?.chartLabels} />
      )}
    </>
  );
}

export default MemberProjectCount;
