import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import BreadCrumps from '../components/BreadCrumps';
import Page from '../components/Page';

export const CreateTask = () => {
  const [members, setMembers] = useState([]);
  const [projectCategory, setProjectCategory] = useState();
  const [subTasks, setSubTasks] = useState();
  const [catClicked, setCatClicked] = useState(true);

  const getMembers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/members`, {
        params: { projectId: id },
      })
      .then((res) => {
        console.log('membersss', res.data.data.members);
        setMembers(
          res.data.data.members.filter((item) => item.isBlocked === false)
        );
      });
  };

  const getAllList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/tasklist/list`, {}).then((res) => {
      console.log('getAllList', res);
      setProjectCategory(res.data.data.tasks);
      // setSubTasks(res.data.data.tasks?.map((sc, i) => sc));
      console.log('getdsf', res.data.data.tasks[0]?.subTask[0]);
    });
  };
  // const subTasks = () => {
  //   projectCategory.subTask.map((pc) => pc);
  // };

  const navigate = useNavigate();
  const { id } = useParams();
  console.log('project', id);

  useEffect(() => {
    getMembers();
    getAllList();
  }, []);

  const TaskSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    category: Yup.string().required('Category required'),
    startDate: Yup.date().optional(),
    endDate: Yup.date().optional(),
  });
  const formik = useFormik({
    initialValues: {
      projectId: id,
      name: '',
      category: '',
      description: '',
      startDate: '',
      endDate: '',
      members: [],
    },
    validationSchema: TaskSchema,
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/task`, values)
          .then((res) => {
            toast.success(res.data.message);
            navigate(-1);
          })
          .catch((err) => {
            toast.error('something went wrong');
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const handleMenuClick = (pc) => {
    setCatClicked(false);
    console.log(pc);
    setSubTasks(
      pc.subTask.map((subTask, i) => (
        <MenuItem key={i} value={subTask.name}>
          {subTask.name}
        </MenuItem>
      ))
    );
  };

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  return (
    <Page title="Project : Add task">
      <Container>
        <Grid container spacing={3} rowGap={3}>
          <Grid item xs={12}>
            <BreadCrumps singlePage={'Create Task'} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Typography variant="h6">Category</Typography> */}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="category"
                              label="Select Category"
                              {...getFieldProps('category')}
                              onChange={formik.handleChange}
                            >
                              {projectCategory?.map((pc, i) => (
                                <MenuItem key={i} value={pc.name} onClick={() => handleMenuClick(pc)}>
                                  {pc.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Typography variant="h6">Name</Typography> */}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {catClicked ? 'Choose a Category' : 'Select Name'}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="name"
                              label="Select name"
                              sx={{ width: '100%', height: '55px' }}
                              {...getFieldProps('name')}
                              onChange={formik.handleChange}
                              disabled={catClicked}
                            >
                              {subTasks}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Typography variant="h6">Description</Typography> */}
                          <TextField
                            label="Enter description"
                            name="description"
                            type="text"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            fullWidth
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={5} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">Start date</Typography>
                          <TextField
                            label=""
                            name="startDate"
                            type="date"
                            {...getFieldProps('startDate')}
                            error={Boolean(touched.startDate && errors.startDate)}
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={5} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">End date</Typography>
                          <TextField
                            label=""
                            name="endDate"
                            type="date"
                            {...getFieldProps('endDate')}
                            error={Boolean(touched.endDate && errors.endDate)}
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Typography variant="h6">Members</Typography> */}
                          <FormControl fullWidth>
                            <Autocomplete
                              multiple
                              id="multiple-limit-tags"
                              name="members"
                              options={members}
                              getOptionLabel={(option) => option.fullname}
                              onChange={(e, value) => {
                                const temp = value.map((item, index) => item._id);
                                setFieldValue('members', temp);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select members"
                                  placeholder="Members"
                                  onChange={handleChange}
                                // {...getFieldProps('members')}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ width: 40, height: 40, cursor: 'pointer' }} src={option?.profilePic}>
                                      {option.fullname.charAt(0).toUpperCase()}
                                    </Avatar>
                                    <Stack direction={'column'}>
                                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                        {option.fullname}
                                      </Typography>
                                      <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                        {option.email}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </li>
                              )}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" marginTop={2} justifyContent="center" alignItems="center" spacing={2}>
                          <Button variant="contained" color="buttonone" type="submit">
                            Create
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
