import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, Stack, styled, TextField, Typography } from '@mui/material';
// component
import axios from 'axios';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function AddAttendanceForm({ closePopup, getApi, options }) {
  const [icon, setIcon] = useState(null);
  const [employees, setEmployees] = useState([]);

  const RegisterSchema = Yup.object().shape({
    employee: Yup.string().required('Employee is required'),
    date: Yup.string().required('Date is required'),
    checkIn: Yup.string().required('Check In is required'),
    checkOut: Yup.string().required('Check Out is required'),
  });

  const formik = useFormik({
    initialValues: {
      employee: '',
      date: '',
      checkIn: '',
      checkOut: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log('clicked');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/admin`, values);
        console.log(response);
        if (!response.data.isError) {
          toast.success(response.data.message);
          getApi(options);
          closePopup();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        return false;
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  const getEmployees = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/allthemembers`);
      if (!res.data.isError) {
        setEmployees(res.data.data.members);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Date'} />
              <TextField
                size="small"
                fullWidth
                type="date"
                placeholder="Date"
                {...getFieldProps('date')}
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Employee'} />
              <Autocomplete
                size="small"
                fullWidth
                options={employees}
                getOptionLabel={(option) => option.fullname}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, value) => {
                  formik.setFieldValue('employee', value._id);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Check In'} />
              <TextField
                size="small"
                fullWidth
                id="datetime-local"
                label="Check In"
                type="datetime-local"
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...getFieldProps('checkIn')}
                error={Boolean(touched.checkIn && errors.checkIn)}
                helperText={touched.checkIn && errors.checkIn}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Check Out'} />
              <TextField
                size="small"
                fullWidth
                id="datetime-local"
                label="Check Out"
                type="datetime-local"
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...getFieldProps('checkOut')}
                error={Boolean(touched.checkOut && errors.checkOut)}
                helperText={touched.checkOut && errors.checkOut}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ fontFamily: 'Inter' }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
