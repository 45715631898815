import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  MenuItem,
  Typography,
  Avatar,
  styled,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function EditLeadForm({ lead, editMode, setEditMode, refetch }) {
  const [leadCategories, setLeadCategories] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().optional(),
    email: Yup.string().optional().email('Email must be a valid email'),
    phone: Yup.string().optional(),
    address: Yup.string().optional(),
    source: Yup.string().required('Source is required'),
    otherSource: Yup.string().optional(),
    category: Yup.array().required('Category is required').min(1, 'Category is required'),
    companyName: Yup.string().optional(),
    businessType: Yup.string().optional(),
    title: Yup.string().optional(),
    status: Yup.string().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
      source: '',
      otherSource: '',
      category: [],
      companyName: '',
      businessType: '',
      title: '',
      status: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/lead/admin`, {
          id: lead._id,
          ...values,
        });
        if (!response.data.isError) {
          toast.success(response.data.message);
          setEditMode(!editMode)
          // refetch();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        return false;
      }
    },
  });

  const getLeadCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/admin/all/active`);
      setLeadCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadSources = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/admin/all/active`);
      setLeadSources(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadStatuses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/admin/all/list`);
      setLeadStatuses(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeadCategories();
    getLeadSources();
    getLeadStatuses();
  }, []);

  useEffect(() => {
    formik.setValues({ ...lead, source: lead.source._id, category: lead.category.map((item) => item._id), status: lead.status._id, });
  }, [])


  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps, handleChange } = formik;


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="Full name"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              placeholder="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="asynchronous-demo"
              fullWidth
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadSources}
              value={leadSources.find((source) => source._id === formik.values.source) || null}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('source', value._id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Source"
                  error={Boolean(formik.touched.source && formik.errors.source)}
                  helperText={formik.touched.source && formik.errors.source}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>

          {values.source && values.source.toLowerCase() === 'other' && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder="Other Source"
                {...getFieldProps('otherSource')}
                error={Boolean(touched.otherSource && errors.otherSource)}
                helperText={touched.otherSource && errors.otherSource}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="category-autocomplete"
              fullWidth
              multiple
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadCategories}
              value={leadCategories.filter((category) => formik.values.category.includes(category._id))}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('category', value.map((item) => item._id));
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Category"
                  error={Boolean(formik.touched.category && formik.errors.category)}
                  helperText={formik.touched.category && formik.errors.category}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Company Name"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Business Type"
              {...getFieldProps('businessType')}
              error={Boolean(touched.businessType && errors.businessType)}
              helperText={touched.businessType && errors.businessType}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Title"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="status-autocomplete"
              fullWidth
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={leadStatuses}
              value={leadStatuses.find((status) => status._id === formik.values.status) || null}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('status', value._id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Status"
                  error={Boolean(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>



          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
