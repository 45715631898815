import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tooltip,
  Zoom,
  TablePagination,
  TableHead,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  Divider,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import Moment from 'moment';
import { Form, useFormik, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Filter from '../components/TransactionsFilter';
import BreadCrumps from '../components/BreadCrumps';
import { commonHeadStyle, commonBodyStyle } from '../theme/commonStyles';

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: '#0D4CA1',
  },
});

export default function Billing() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState();
  const [pages, setPages] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0, projectId: id });
  const [estimateAmount, setEstimateAmount] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [status, setStatus] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [transactionDate, setTransactionDate] = useState(Moment.utc().format('YYYY-MM-DD'));
  const [file, setFile] = useState('');
  const [estimateExist, setEsitimateExist] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalReceived, setTotalReceived] = useState(0);
  const [billId, setBillId] = useState('');

  const getTransactions = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/project/all`, {
        params: options,
      });
      console.log('res', res);
      setTransactionData(res.data.data.data.transactions);
      setTransactionCount(res.data.data.data.maxRecords);
      setTotalReceived(res.data.data.data.totalReceived);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getEstimate = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/estimate`, {
        params: { projectId: id },
      });
      console.log('estimate', res);
      setEstimateAmount(res.data.data.data.estimatedAmount);
      setProjectName(res.data.data.data.name);
      setEsitimateExist(Boolean(res.data.data.data.estimatedAmount));
      setEditMode(Boolean(!res.data.data.data.estimatedAmount));
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const transactionSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    date: Yup.string().required('Date is required'),
    remark: Yup.string().required('Remark is required'),
    status: Yup.string().optional(),
    receivedDate: Yup.string().optional(),
    transactionId: Yup.string().optional(),
  });
  const formik = useFormik({
    initialValues: {
      projectId: id,
      amount: '',
      date: Moment.utc().format('YYYY-MM-DD'),
      remark: '',
      receivedDate: Moment.utc().format('YYYY-MM-DD'),
      transactionId: '',
    },
    validationSchema: transactionSchema,
    // eslint-disable-next-line consistent-return
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/transaction/add`, values, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            formik.resetForm();
            toast.success(res.data.message);
            handleClose();
            getTransactions(options);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const updateEstimatedAmount = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/estimate`, {
        projectId: id,
        amount: estimateAmount,
      });
      toast.success(res.data.message);
      getEstimate();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const updateStatus = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/transaction/status`,
        {
          id: billId,
          status,
          receivedDate: transactionDate,
          file,
          transactionId,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success(res.data.message);
      setOpen2(false);
      setTransactionDate('');
      setTransactionId('');
      setStatus('');
      setTransactionId();
      getTransactions(options);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleStatusChangePopup = (e, item) => {
    setStatus(e.target.value);
    setBillId(item._id);
    setOpen2(true);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getTransactions(temp);
  };

  const getAttachment = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/attachment`, {
        params: { id },
      });
      window.open(res.data.data.fileURL, '_blank');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setOpen1(false);
    setOpen2(false);
    formik.resetForm();
    getTransactions(options);
  };
  const applyFilters = (filter) => {
    const temp = { page: 0, size, projectId: id };

    if (filter.transactionId.trim()) {
      temp.transactionId = filter.transactionId.trim();
    }
    if (filter.status.length) {
      temp.status = filter.status;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnStartDate.length) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate.length) {
      temp.txnEndDate = filter.txnEndDate;
    }
    setOptions(temp);
    setPages(0);
    getTransactions(temp);
  };
  const { errors, touched, handleSubmit, isSubmitting, setFieldValue, values, getFieldProps } = formik;

  useEffect(() => {
    getTransactions(options);
    getEstimate();
  }, []);

  return (
    <Page title="Dashboard : view_project ">
      <Container maxWidth="xl">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={3} rowGap={3}>
            {/* Add transaction dialog */}
            <Dialog
              open={open1}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  Add Transaction
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container mt={1} spacing={1.5}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Date"
                          type="date"
                          {...getFieldProps('date')}
                          error={Boolean(touched.date && errors.date)}
                          helperText={touched.date && errors.date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Amount"
                          type="number"
                          {...getFieldProps('amount')}
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Remark"
                          type="text"
                          {...getFieldProps('remark')}
                          error={Boolean(touched.remark && errors.remark)}
                          helperText={touched.remark && errors.remark}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Status"
                          select
                          defaultValue="PENDING"
                          onChange={(e) => setFieldValue('status', e.target.value)}
                        >
                          <MenuItem value="PENDING">PENDING</MenuItem>
                          <MenuItem value="RECEIVED">RECEIVED</MenuItem>
                        </TextField>
                      </Grid>
                      {values.status === 'RECEIVED' && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Received Date"
                            defaultValue={Moment.utc().format('YYYY-MM-DD')}
                            type="date"
                            {...getFieldProps('receivedDate')}
                            error={Boolean(touched.receivedDate && errors.receivedDate)}
                            helperText={touched.receivedDate && errors.receivedDate}
                          />
                        </Grid>
                      )}
                      {values.status === 'RECEIVED' && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Transaction ID"
                            type="text"
                            {...getFieldProps('transactionId')}
                            error={Boolean(touched.transactionId && errors.transactionId)}
                            helperText={touched.transactionId && errors.transactionId}
                          />
                        </Grid>
                      )}
                      {values.status === 'RECEIVED' && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Attach File"
                            InputLabelProps={{ shrink: true }}
                            type="file"
                            onChange={(event) => formik.setFieldValue('file', event.target.files[0])}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="flex-end">
                          <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            type="submit"
                            sx={{ width: '100px' }}
                            color="buttonone"
                          >
                            Add
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Dialog>

            {/* Add transaction dialog end */}

            {/* Status change dialog */}
            <Dialog
              open={open2}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" sx={{ height: '40px' }}>
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  Update
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Grid container mt={0.5} spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      fullWidth
                      value={transactionDate}
                      label="Received Date"
                      onChange={(e) => setTransactionDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Attach File"
                      InputLabelProps={{ shrink: true }}
                      type="file"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      fullWidth
                      label="Transaction ID"
                      onChange={(e) => setTransactionId(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent="center">
                      <LoadingButton
                        variant="contained"
                        onClick={updateStatus}
                        sx={{ width: '100px' }}
                        color="buttonone"
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

            {/* Status change dialog end */}

            <Grid item xs={12}>
              <BreadCrumps project={{ id, name: projectName }} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '12px',
                }}
              >
                <Stack spacing={2}>
                  <Stack
                    // padding={2}
                    paddingTop={3}
                    paddingBottom={2}
                    paddingX={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'space-between' },
                      margin: '5px',
                      alignItems: { xs: 'flex-end', sm: 'center' },
                    }}
                  >
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      {(!estimateExist || editMode) && (
                        <Stack direction={'row'} spacing={1}>
                          <CssTextField
                            size="small"
                            label="Estimated Amount"
                            InputLabelProps={{ shrink: true }}
                            value={estimateAmount}
                            onChange={(e) => setEstimateAmount(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Iconify
                                    icon="material-symbols:close"
                                    onClick={() => setEditMode(false)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button size="small" onClick={updateEstimatedAmount} variant="contained" color="buttonone">
                            Update
                          </Button>
                        </Stack>
                      )}

                      {estimateExist && !editMode && (
                        <Stack direction={'row'} spacing={1}>
                          <CssTextField
                            size="small"
                            label="Estimated Amount"
                            value={`₹ ${estimateAmount}`}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Iconify
                                    icon="ant-design:edit-filled"
                                    onClick={() => setEditMode(true)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      )}
                      <CssTextField
                        value={`₹ ${totalReceived}`}
                        size="small"
                        sx={{ pointerEvents: 'none' }}
                        label="Total Received"
                        onChange={(e) => null}
                      />
                    </Stack>

                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      <Button
                        startIcon={<AddCircleOutlineSharpIcon />}
                        onClick={() => setOpen1(true)}
                        variant="contained"
                        color="buttonone"
                      >
                        Transaction
                      </Button>
                      <Filter applyFilters={applyFilters} />
                    </Stack>
                  </Stack>
                  <Divider
                    sx={{
                      background: ' rgba(0, 0, 0, 0.50)',
                      marginBottom: '24px',
                    }}
                  />
                  <Stack padding={2}>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                              <TableCell sx={commonHeadStyle}>Date</TableCell>
                              <TableCell sx={commonHeadStyle}>Transaction ID</TableCell>
                              <TableCell sx={commonHeadStyle}>Amount</TableCell>
                              <TableCell sx={commonHeadStyle}>Remark</TableCell>
                              <TableCell sx={commonHeadStyle}>Status</TableCell>
                              <TableCell sx={commonHeadStyle}>Recieved On</TableCell>
                              <TableCell sx={commonHeadStyle}>File</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transactionData?.map((item, id) => (
                              <TableRow key={id}>
                                <TableCell sx={commonBodyStyle}>{pages * size + (id + 1)}</TableCell>
                                <TableCell sx={commonBodyStyle}>{Moment.utc(item.date).format('MMM DD, YYYY')}</TableCell>
                                <CopyToClipboard
                                  text={item.transactionId}
                                  onCopy={() => toast.info('Transaction ID copied')}
                                >
                                  <TableCell sx={commonBodyStyle} style={{ cursor: 'pointer' }}>
                                    {item.transactionId}
                                  </TableCell>
                                </CopyToClipboard>
                                <TableCell sx={commonBodyStyle}>{`₹${item.amount}`}</TableCell>
                                <TableCell sx={commonBodyStyle}>{item.remark}</TableCell>
                                {item?.status === 'PENDING' ? (
                                  <TableCell sx={commonBodyStyle}>
                                    <TextField
                                      select
                                      value={item.status}
                                      size="small"
                                      onChange={(e) => handleStatusChangePopup(e, item)}
                                    >
                                      <MenuItem value="RECEIVED">RECEIVED</MenuItem>
                                      <MenuItem value="PENDING">PENDING</MenuItem>
                                    </TextField>
                                  </TableCell>
                                ) : (
                                  <TableCell sx={commonBodyStyle}>{item.status}</TableCell>
                                )}
                                <TableCell sx={commonBodyStyle}>
                                  {item.receivedDate && Moment.utc(item.receivedDate).format('MMM DD, YYYY')}
                                </TableCell>
                                <TableCell sx={commonBodyStyle}>
                                  {item.attachment && (
                                    <>
                                      <Iconify
                                        sx={{ fontSize: '24px', cursor: 'pointer' }}
                                        icon="carbon:document-view"
                                        onClick={() => getAttachment(item._id)}
                                      />
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component={'div'}
                      count={transactionCount}
                      onPageChange={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      page={pages}
                      showFirstButton
                      showLastButton
                    />
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
