import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Dialog,
  Divider,
  Avatar,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import axios from 'axios';
import Scrollbar from './Scrollbar';
// mock
import LoadingIcon from './smallComponents/LoadingIcon';
import { commonHeadStyle, commonBodyStyle } from '../theme/commonStyles';
import PopupComponent from './PopupComponent';

// import { FilterForm } from '../components/FilterForm';

// ----------------------------------------------------------------------

export default function LeadStatuss() {
  const navigate = useNavigate();
  const [projectStatusData, setProjectStatusData] = useState();
  const [pages, setPages] = useState(0);
  const [projectStatusCount, setProjectStatusCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getLeadStatuss(options);
  }, []);

  const getLeadStatuss = (options) => {
    console.log(options);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadStatus/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('ressssssssssss', res);
        if (res.data.isError === false) {
          setProjectStatusCount(res.data.data.maxRecords);
          setProjectStatusData(res.data.data.records);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isactive}` === event.target.value) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleAddModal = () => {
    setOpenModal(!openModal);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    console.log(refresh);
    if (refresh) {
      getLeadStatuss(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getLeadStatuss(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.name.trim()) {
      temp.name = filter.name.trim();
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked;
    }
    setOptions(temp);
    setPages(0);
    getLeadStatuss(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(projectStatusCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getLeadStatuss(temp);
  };

  const handleAddSuccess = () => {
    getLeadStatuss(options);
  };

  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Card>
            <Stack direction="row" spacing={2} padding={2} justifyContent={'space-between'}>
              <Stack direction="row" spacing={1.5}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={goToPage}
                  sx={{
                    backgroundColor: '#0096DB',
                    height: '40px',
                  }}
                >
                  Go
                </Button>
              </Stack>
              {/* <Filter applyFilters={applyFilters} /> */}
            </Stack>
            <Divider
              sx={{
                background: ' rgba(0, 0, 0, 0.50)',
                marginBottom: '24px',
              }}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                      <TableCell sx={commonHeadStyle}>Name</TableCell>
                      <TableCell sx={commonHeadStyle}>Priority</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectStatusData?.map((item, id) => (
                      <TableRow key={id}>
                        <TableCell sx={{ ...commonBodyStyle, width: '120px' }}>{pages * size + (id + 1)}</TableCell>
                        <TableCell
                          sx={{ ...commonBodyStyle, cursor: 'pointer' }}
                          onClick={() => navigate(`/dashboard/member/${item._id}`)}
                        >
                          <Typography sx={commonBodyStyle}>{item.name}</Typography>
                        </TableCell>

                        <TableCell sx={commonBodyStyle}>
                          <Typography sx={commonBodyStyle}>{item?.priority}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[]}
              component={'div'} 
              count={projectStatusCount}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              page={pages}
              showFirstButton
              showLastButton
            />
          </Card>
        </>
      )}
    </>
  );
}
