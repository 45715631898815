// @mui
import { Card, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// utils
import { Edit } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { fShortenNumber, memorySize } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppDetailsCard.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    sx: PropTypes.object,
    other: PropTypes.object,
    memberCount: PropTypes.number,
    storageLimit: PropTypes.number,
    currentPlanStartDate: PropTypes.string,
    planPrice: PropTypes.number,
    membersUsed: PropTypes.number,
    storageUsed: PropTypes.number,
    current: PropTypes.bool,

};

export default function AppDetailsCard({
    title,
    color = 'primary',
    sx,
    memberCount,
    storageLimit,
    startDate,
    planPrice,
    membersUsed,
    storageUsed,
    current = false,
    setOpen,
    setDeleteOpen,
    future = false,
    ...other
}) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openMenu = Boolean(anchorEl);

    return (
        <Card
            sx={{
                py: 3,
                boxShadow: 0,
                textAlign: 'center',
                position: 'relative',
                color: (theme) => theme.palette[color].darker,
                ...sx,
            }}
            {...other}
        >
            {future &&
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: (theme) => theme.palette[color].darker,
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    <Edit />
                </IconButton>
            }
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem onClick={() => setOpen(true)}>Edit</MenuItem>
                <MenuItem onClick={() => setDeleteOpen(true)}>Delete</MenuItem>
            </Menu>

            <Typography variant="h5" pb={1}>{title}</Typography>

            <Grid container spacing={3} marginY={1}>
                <Grid item xs={12} sm={12} md={6}>
                    <Stack>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                            Start Date:
                        </Typography>
                        <Typography variant='h6' >
                            {moment(startDate).format("DD MMM YYYY")}
                        </Typography>
                    </Stack>

                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Stack >
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                            Plan Price:
                        </Typography>
                        <Typography variant='h6' >
                            ₹ {planPrice}/-
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Stack >
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                            Max Members
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} justifyContent={"center"}>
                            {current && <Typography variant='caption' sx={{ opacity: 0.72 }}>
                                {fShortenNumber(membersUsed) || 0} /
                            </Typography>}
                            <Typography variant='h6'>
                                {fShortenNumber(memberCount)}
                            </Typography>

                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Stack >
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                            Storage
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} justifyContent={"center"}>
                            {current && <Typography variant='caption' sx={{ opacity: 0.72 }}>
                                {memorySize(storageUsed) || 0} /
                            </Typography>}
                            <Typography variant='h6' >
                                {memorySize(storageLimit)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>

            </Grid>
        </Card>
    );
}