import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Icon,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Dialog,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import Moment from 'moment';
import { Box } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import { EditRounded, RemoveRedEyeRounded } from '@mui/icons-material';
import axios from 'axios';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// mock
import PopupComponent from '../components/PopupComponent';
import AddHeirarchy from '../components/AddHeirarchy';
import PopupIsBlockedChange from '../components/PopUpIsBlockedChange';
import EditHeirarchy from '../components/EditHeirarchy';

// import { FilterForm } from '../components/FilterForm';
import { commonHeadStyle, commonBodyStyle } from '../theme/commonStyles';

// ----------------------------------------------------------------------

// const customToolBar = ({setFilter})
export default function TaskList() {
  const navigate = useNavigate();
  const [taskListData, setTaskListData] = useState();
  const [pages, setPages] = useState(0);
  const [taskListCount, setTaskListCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const size = 10;
  const [options, setOptions] = useState({});
  const [input, setInput] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [taskListToEdit, setTaskListToEdit] = useState({});

  useEffect(() => {
    getTaskLists();
  }, []);

  const getTaskLists = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/taskList/all`, {})
      .then((res) => {
        console.log('ressssssssssss', res);
        if (res.data.isError === false) {
          setTaskListCount(res.data.data.maxRecords);
          setTaskListData(res.data.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isactive}` === event.target.value) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleAddModal = () => {
    setOpenModal(!openModal);
  };

  const handleClose = () => {
    setSelectedIndex();
  };

  const handleEditModal1 = () => {
    setOpenModal1(!openModal1);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.name.trim()) {
      temp.name = filter.name.trim();
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.role.length) {
      temp.role = filter.role;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked;
    }
    setOptions(temp);
    setPages(0);
    getTaskLists(temp);
  };

  const sortChildWithPriority = (taskList) => taskList.sort((a, b) => a.priority - b.priority);

  useEffect(() => {
    const taskListToEditItem = taskListData?.find((item) => item._id === taskListToEdit?._id);
    if (taskListToEditItem) {
      setTaskListToEdit(taskListToEditItem);
    }
  }, [taskListData]);

  return (
    <>
      <Page title="Heirarchy">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Dialog open={openModal1} onClose={handleEditModal1}>
              <PopupComponent closePopup={handleEditModal1} title={'Update Heirarchy'}>
                <EditHeirarchy closePopup={handleEditModal1} getTaskLists={getTaskLists} item={taskListToEdit} />
              </PopupComponent>
            </Dialog>
            <ToastContainer />
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="end" my={5}>
                <Dialog open={openModal} onClose={handleAddModal}>
                  <PopupComponent closePopup={handleAddModal} title={'Create Heirarchy'}>
                    <AddHeirarchy closePopup={handleAddModal} getTaskLists={getTaskLists} />
                  </PopupComponent>
                </Dialog>

                <Stack direction="row" spacing={1.5}>
                  <Button variant="contained" onClick={handleAddModal} color="buttonone">
                    New Heirarchy
                  </Button>
                </Stack>
              </Stack>
              <Card>
                {/* <Stack direction="row" spacing={2} padding={2} justifyContent={'end'}>
                  <Filter applyFilters={applyFilters} />
                </Stack> */}
                {/* <Divider
                  sx={{
                    background: ' rgba(0, 0, 0, 0.50)',
                    marginBottom: '24px',
                  }} 
                /> */}
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                          <TableCell sx={commonHeadStyle}>Name</TableCell>
                          <TableCell sx={commonHeadStyle}>Priority</TableCell>
                          <TableCell sx={commonHeadStyle}>Child Tasks</TableCell>
                          <TableCell sx={commonHeadStyle}>Status</TableCell>
                          <TableCell sx={commonHeadStyle}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taskListData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ ...commonBodyStyle, width: '60px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item.name}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item.priority}</TableCell>
                            <TableCell sx={commonBodyStyle}>
                              {/* {item?.subTask?.map((taskItem, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography sx={commonBodyStyle}>{taskItem.name}</Typography>
                                  <Typography sx={commonBodyStyle}>{taskItem.priority}</Typography>
                                </Box>
                              ))} */}
                              {item?.subTask?.length > 0 ? (
                                sortChildWithPriority(item.subTask).map((taskItem, index) => (
                                  <Stack key={index} direction={'row'} spacing={1}>
                                    <Typography sx={{ ...commonBodyStyle, width: '15px' }}>
                                      {taskItem.priority}.{' '}
                                    </Typography>
                                    <Typography sx={commonBodyStyle}>{taskItem.name}</Typography>
                                  </Stack>
                                ))
                              ) : (
                                <Typography sx={commonBodyStyle}>No Child Tasks</Typography>
                              )}
                            </TableCell>
                            <TableCell sx={commonBodyStyle}>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                  size="small"
                                  sx={commonBodyStyle}
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem sx={commonBodyStyle} value="false">
                                    Active
                                  </MenuItem>
                                  <MenuItem sx={commonBodyStyle} value="true">
                                    Blocked
                                  </MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <PopupIsBlockedChange
                                    item={item}
                                    api={getTaskLists}
                                    handleClose={handleClose}
                                    type={'Heirarchy'}
                                    path={'/taskList'}
                                  />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell sx={commonBodyStyle}>
                              <IconButton
                                onClick={() => {
                                  setTaskListToEdit(item);
                                  handleEditModal1();
                                }}
                              >
                                <EditRounded />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                {/* <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={taskListCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  page={pages}
                  showFirstButton
                  showLastButton
                /> */}
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
