// @mui
import { Card, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// sections
import AddCategoryForm from './AddCategoryForm';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // backgroundColor: 'black',
  // padding: theme.spacing(12, 0),
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddCategory({ closePopup, handleAddSuccess }) {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Container>
      <ContentStyle>
        <AddCategoryForm closePopup={closePopup} handleAddSuccess={handleAddSuccess} />
      </ContentStyle>
    </Container>
  );
}
