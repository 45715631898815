import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Container, Stack } from '@mui/material';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import LeadCategories from '../components/LeadCategories';
import LeadSources from '../components/LeadSources';
import LeadStatuss from '../components/LeadStatuses';

function CrmSettings() {
  const [value, setValue] = useState('categories'); // Default to 'categories'
  const [searchParams, setSearchParams] = useSearchParams();

  const tabValue = searchParams.get('tab');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    } else {
      setValue('categories');
      setSearchParams({ tab: 'categories' });
    }
  }, [tabValue, setSearchParams]);

  return (
    <Page title="CRM Settings">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <PageHeader title="CRM Settings" />
        </Stack>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Lead Categories" value="categories" />
          <Tab label="Lead Sources" value="sources" />
          <Tab label="Lead Statuses" value="statuses" />
        </Tabs>
        {value === 'categories' && <LeadCategories />}
        {value === 'sources' && <LeadSources />}
        {value === 'statuses' && <LeadStatuss />}
      </Container>
    </Page>
  );
}

export default CrmSettings;
