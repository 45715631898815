import * as React from 'react';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { TextField, Button, Stack, Box, MenuItem, Collapse, Grid } from '@mui/material';

const Filter = ({ open, filterValues, applyFilters }) => (
  <Collapse in={open}>
    <Formik
      initialValues={{
        userId: filterValues.userId,
        name: filterValues.name,
        email: filterValues.email,
        startDate: filterValues.startDate ? moment.utc(filterValues.startDate).format('YYYY-MM-DD') : '',
        endDate: filterValues.endDate ? moment.utc(filterValues.endDate).format('YYYY-MM-DD') : '',
        isBlocked: filterValues.isBlocked,
        role: filterValues.role,
      }}
      onSubmit={async (values) => {
        applyFilters(values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="userId"
                name="userId"
                label="Member ID"
                value={values.userId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Member name"
                value={values.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                fullWidth
                id="role"
                name="role"
                label="Role"
                value={values.role}
                onChange={handleChange}
              >
                <MenuItem value="EMPLOYEE">Employee</MenuItem>
                <MenuItem value="CLIENT">Client</MenuItem>
                <MenuItem value="CONTRACTOR">Contractor</MenuItem>
                <MenuItem value="PARTNER">Partner</MenuItem>
                <MenuItem value="ARCHITECT">Architect</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Status"
                value={values.isBlocked}
                name="isBlocked"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.isBlocked && errors.isBlocked)}
                helperText={touched.isBlocked && errors.isBlocked}
              >
                <MenuItem value="false">Active</MenuItem>
                <MenuItem value="true">Blocked</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction={'row'} spacing={2}>
                <TextField
                  type="date"
                  name="startDate"
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  value={values.startDate}
                  onChange={handleChange}
                />
                <p style={{ paddingTop: '15px' }}>to</p>
                <TextField
                  type="date"
                  name="endDate"
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  value={values.endDate}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit" style={{ marginTop: '10px' }} color="buttonone">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Collapse>
);

export default Filter;
