import { Grid, Stack, Box, Typography, TablePagination, CircularProgress, Tooltip, Zoom } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { CheckCircle, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

export const ListTask = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 9;

  const getTask = (newPage) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/categories/tasks`, {
        params: { projectId: id, size, page: newPage },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.records);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      })
      .catch((err) => {
        console.log(err); 
        setIsLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    getTask(newPage);
  };
  useEffect(() => {
    getTask();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {task?.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Box
                sx={{
                  background: '#fff',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  height: '100%',
                }}
                px={3}
                py={3}
                onClick={() => navigate(`/dashboard/project/projects/task?category=${item.category}&id=${id}`)}
              >
                <Typography
                  textAlign={'center'}
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.09px',
                  }}
                  mb={3}
                >
                  {item.category}
                </Typography>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    textAlign: 'end',
                    fontFamily: 'Work Sans',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                  mb={0.5}
                >{`${item?.completedTaskCount}/${item?.totalTaskCount}`}</Typography>
                <Box sx={{ width: '100%' }} mb={4}>
                  <ProgressBar
                    completed={item.completedTaskPercentage}
                    baseBgColor="#C0BEBE"
                    bgColor={item.completedTaskPercentage < 60 ? '#5297FF' : '#0ED652'}
                    maxCompleted={100}
                    height="10px"
                    width="100%"
                    customLabelStyles={{
                      fontSize: '10px',
                      color: `${item.completedTaskPercentage < 60 ? '#5297FF' : '#0ED652'}`,
                    }}
                  />
                </Box>
                <Stack
                  direction={'column'}
                  spacing={3}
                  sx={{
                    height: { xs: 'auto', sm: '100%' },
                    maxHeight: { xs: 'auto', sm: '400px' },
                    overflowY: { xs: 'auto', sm: 'scroll' },
                  }}
                  className="scrollCard"
                >
                  {item.task.map((name, id) => (
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={id}>
                      <Typography
                        sx={{
                          color: name?.status === 'COMPLETED' ? '#919191' : '#414141',
                          fontFamily: 'Work Sans',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: 'normal',
                          letterSpacing: '-0.08px',
                        }}
                      >
                        {name.name}
                      </Typography>

                      <Tooltip
                        title={name?.status?.replaceAll('_', ' ')}
                        placement="top"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        {name?.status === 'COMPLETED' ? (
                          <CheckCircle sx={{ color: '#53D0B3' }} />
                        ) : (
                          <CheckCircleOutlineOutlined sx={{ color: '#53D0B3' }} />
                        )}
                      </Tooltip>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Grid>
          ))}
        </>
      )}
      <Grid item xs={12}>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={taskCount}
          page={pages}
          onPageChange={handlePageChange}
          rowsPerPage={size}
        />
      </Grid>
    </>
  );
};
