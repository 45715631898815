import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const DeleteNote = (props) => {
  const matches = useMediaQuery('(max-width:600px)');

  const { getNotes, open, setOpen, userId, noteId } = props;

  const formik = useFormik({
    initialValues: {
      id: userId,
      noteId: props.noteId,
    },
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/member/remove-note`, values);
        console.log(res.data);
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getNotes();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { values, isSubmitting, handleSubmit, resetForm } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  useEffect(() => {
    formik.setFieldValue('id', userId);
    formik.setFieldValue('noteId', noteId);
  }, [userId, noteId]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{'Delete Note'}</DialogTitle>
      <Box width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Typography>Are you sure you want to delete this note?</Typography>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  {'Submit'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteNote;
