import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
// component
import axios from 'axios';
import { toast } from 'react-toastify';
import FileInputField from './FileInputField';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const StyledLabelTypography = ({ title }) => {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  }));
  return (
    <StyledTypography>
      {title}
      <span style={{ color: 'red' }}>*</span>
    </StyledTypography>
  );
};

export default function AddAdminForm({ closePopup, callAPI }) {
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Full name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      profilePic: '',
      isBlocked: false,
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log('clicked');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response);
        if (!response.data.isError) {
          toast.success(response.data.message);
          closePopup();
          callAPI();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (values.profilePic) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setProfileImage(reader.result);
        }
      };
      reader.readAsDataURL(values.profilePic);
    }
  }, [values.profilePic]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2} mt={1}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{
                xs: 1.5,
                sm: 3,
              }}
              alignItems={{ xs: 'left', sm: 'center' }}
              sx={{ width: '100%' }}
            >
              <StyledLabelTypography title={'Photo'} />
              <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ width: '100%' }}>
                <Avatar alt="Remy Sharp" src={profileImage} sx={{ width: 56, height: 56 }} />
                <FileInputField
                  onChange={(e) => formik.setFieldValue('profilePic', e.target.files[0])}
                  value={formik.values.profilePic}
                  accept=".jpg, .jpeg, .png"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Full name'} />
              <TextField
                size="small"
                fullWidth
                placeholder="Full name"
                {...getFieldProps('fullname')}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Email'} />
              <TextField
                size="small"
                fullWidth
                autoComplete="adminname"
                type="email"
                placeholder="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Password'} />
              <TextField
                size="small"
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'column'} spacing={1.5}>
              <StyledLabelTypography title={'Status'} />
              <TextField
                select
                placeholder="Status"
                size="small"
                name="isBlocked"
                {...getFieldProps('isBlocked')}
                onChange={formik.handleChange}
              >
                <MenuItem value={'false'}>Active</MenuItem>
                <MenuItem value={'true'}>Blocked</MenuItem>
              </TextField>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction={'row'} spacing={2} justifyContent={'center'}>
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ fontFamily: 'Inter' }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
