// @mui
import { styled } from '@mui/material/styles';
import { Card, Container } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// sections
import AddLeadSourceForm from './AddLeadSourceForm';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // backgroundColor: 'black',
  // padding: theme.spacing(12, 0),
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddLeadSource({ closePopup, handleAddSuccess }) {
  return (
    <Container>
      <ContentStyle>
        <AddLeadSourceForm closePopup={closePopup} handleAddSuccess={handleAddSuccess} />
      </ContentStyle>
    </Container>
  );
}
