import { Download } from '@mui/icons-material';
import { Avatar, Box, Button, Card, Container, Divider, Grid, IconButton, MenuItem, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { capitalCase } from 'change-case';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Currency from "react-currency-formatter";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import ReactToPrint from 'react-to-print';
// import logo from "../assets/logoblue.png";
// import BackButton from '../components/BackButton';
import Iconify from '../components/Iconify';
import Label from '../components/Label';
// import { InvoicesTrxView } from 'src/sections/invoiceTransactions/view';
import logo from "../assets/logo.png"
import "./inv.css"
import Page from '../components/Page';

function InvoiceView() {

    const [invoice, setInvoice] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("invoiceId")

    const navigate = useNavigate();

    const fetchinvoice = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/invoice/admin/one?id=${id}`);
            if (!res.data.isError) {
                setInvoice(res.data.data)
                setIsLoading(false)
            } else {
                console.log(res.data.message)
                setIsLoading(false)
            }
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchinvoice();
    }, [id])


    const invoiceCardRef = useRef(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const [formData, setFormData] = useState({
        amount: '',
        paymentMode: '',
        paymentReference: '',
        paymentDate: '',
        remarks: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    return (

        <Page title="Invoice Details">
            <Container maxWidth={"xl"}>

                <Stack gap={5}>
                    {
                        !isLoading ? (
                            <>

                                <Grid container spacing={3} alignItems={"center "} direction={"row"}>
                                    <Grid item md={12} >
                                        <Card sx={{ p: 3 }}>
                                            <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                                                <Typography variant='h4'>Invoice Details</Typography>
                                            </Stack>
                                            <Grid container mt={2} rowGap={2}>
                                                <Grid item md={4}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Invoice Number</Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{invoice?.invoiceNumber}</Typography>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Invoice Date</Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{moment(invoice?.invoiceDate).format("ll")}</Typography>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Invoice of the Month</Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{moment(invoice?.invoiceOfTheMonth).format("ll")}</Typography>
                                                </Grid>

                                                <Grid item md={4}>
                                                    <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Payment Status</Typography>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Label color={(invoice.isPaid === false && 'error') || 'success'}>{invoice.isPaid === false ? "Pending" : "Paid"}</Label>

                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Stack direction={"row"} justifyContent={"flex-end"}>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button >
                                                <Download color='black' />  Download Invoice
                                            </Button>
                                        )}
                                        content={() => invoiceCardRef.current}
                                    />
                                </Stack>
                                {/* </Card> */}

                                {/* INVOICE SECTION */}
                                <Card ref={invoiceCardRef} sx={{ px: 2, pt: 3, pb: 20 }}>

                                    <Stack gap={5}>
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Box component={"img"} src={logo} alt='logo' width={"350px"} />
                                            <Stack alignItems={"flex-end"} gap={1}>
                                                <Typography variant='caption' sx={{ color: "#B7B7B7" }}>ORIGINAL FOR ADMIN</Typography>
                                                <Typography variant='h2' sx={{ color: "#B7B7B7" }}>TAX INVOICE</Typography>
                                                <Typography variant='subtitle2' sx={{ color: "#B7B7B7" }}>Invoice number : {invoice.invoiceNumber}</Typography>
                                                <Typography variant='subtitle2' sx={{ color: "#B7B7B7" }}>Invoice date: {moment(invoice.invoiceDate).format("ll")}</Typography>
                                            </Stack>

                                        </Stack>
                                        <Divider />
                                        <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                            <Stack width={"300px"} gap={1}>
                                                <Typography variant='h5'>From</Typography>
                                                <Typography variant='subtitle2' sx={{ color: "gba(0, 0, 0, 0.5);" }}>
                                                    {invoice.from?.name} <br />
                                                    {invoice.from?.address} <br />
                                                    {invoice.from?.email} <br />
                                                    {invoice.from?.phone} <br />
                                                </Typography>
                                            </Stack>
                                            <Stack width={"300px"} gap={1}>
                                                <Typography variant='h5'>To</Typography>
                                                {invoice.to?.name} <br />
                                                {invoice.to?.address} <br />
                                                {invoice.to?.email} <br />
                                                {invoice.to?.phone} <br />
                                            </Stack>
                                        </Stack>

                                        <TableContainer sx={{ minWidth: 800 }}>

                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Date"}</Typography></TableCell>
                                                        <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Description"}</Typography></TableCell>
                                                        <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Plan price"}</Typography></TableCell>
                                                        <TableCell align="right"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Daily price"}</Typography></TableCell>
                                                        <TableCell align="right" />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        invoice?.monthlyBill?.dailyBilling?.map((row) => (
                                                            <TableRow tabIndex={-1} role="checkbox" >
                                                                <TableCell component="th" scope="row" padding="normal">
                                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                                        <Typography variant="subtitle2" noWrap>
                                                                            {moment(row.date).format("ll")}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>

                                                                <TableCell align="left"><Typography variant="subtitle2" noWrap>
                                                                    {"Montly invoice"}
                                                                </Typography></TableCell>

                                                                <TableCell align="left"><Typography variant="subtitle2" noWrap>
                                                                    <Currency quantity={row.currentPlanPrice} currency={"INR"} />
                                                                </Typography></TableCell>

                                                                <TableCell align="right">
                                                                    <Typography variant='subtitle2' fontWeight={500}>  <Currency quantity={row.dailyPrice} currency={"INR"} /></Typography>
                                                                </TableCell>
                                                                <TableCell align="right"><></>
                                                                    {/* <IconButton onClick={() => navigate(`/dashboard/courses/details?id=${row.courseId}`)}>
                                                                <Iconify icon={"wpf:view-file"} width={22} height={22} />
                                                            </IconButton> */}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>

                                            </Table>
                                        </TableContainer>

                                        <Grid container>
                                            <Grid item xs={9} />
                                            <Grid item xs={3}>
                                                <Grid container rowGap={1}>
                                                    <Grid item xs={6}><Typography variant='body1'>Sub Total :</Typography></Grid>
                                                    <Grid item xs={6} align="right">
                                                        <Currency currency={"INR"} quantity={invoice.subTotal} />
                                                    </Grid>
                                                    <Grid item xs={6}><Typography variant='body1'>Grand Total :</Typography></Grid>
                                                    <Grid item xs={6} align="right">
                                                        <Currency currency={"INR"} quantity={invoice.grandTotal} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Card>
                            </>
                        ) : (
                            <Card >
                                <Stack height={"500px"} alignItems={"center"} justifyContent={"center"}>
                                    <ScaleLoader />
                                </Stack>
                            </Card>
                        )
                    }
                    {/* {
                    invoice.isPaid === true &&
                    <InvoicesTrxView invId={invoice._id} />
                } */}


                </Stack>
            </Container>

        </Page>

    )
}

export default InvoiceView