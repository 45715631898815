import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Icon,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Dialog,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import Moment from 'moment';
import { Box } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import { EditRounded, RemoveRedEyeRounded } from '@mui/icons-material';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import AddUser from '../components/AddUser';
import UserPopup from '../components/UserPopup';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Popup from '../components/PopUp';
// mock
import Filter from '../components/filters/UserFilter';
import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';

// import { FilterForm } from '../components/FilterForm';
import { commonHeadStyle, commonBodyStyle } from '../theme/commonStyles';

// ----------------------------------------------------------------------

// const customToolBar = ({setFilter})
export default function User() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [pages, setPages] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    getUsers(options);
  }, []);

  const getUsers = (options) => {
    console.log(options);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/all`, {
        params: options,
      })
      .then((res) => {
        console.log('ressssssssssss', res);
        if (res.data.isError === false) {
          setUserCount(res.data.data.maxRecords);
          setUserData(res.data.data.members);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isactive}` === event.target.value) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleAddModal = () => {
    setOpenModal(!openModal);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    console.log(refresh);
    if (refresh) {
      getUsers(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getUsers(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId) {
      temp.userId = filter.userId.trim();
    }
    if (filter.name) {
      temp.name = filter.name.trim();
    }
    if (filter.email) {
      temp.email = filter.email.trim();
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.role) {
      temp.role = filter.role;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked;
    }
    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(userCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getUsers(temp);
  };

  return (
    <>
      <Page title="Employees">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <ToastContainer />
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <PageHeader title="Employees" />

                <Dialog open={openModal} onClose={handleAddModal}>
                  <UserPopup closePopup={handleAddModal} title={'Create New Employee'}>
                    <AddUser closePopup={handleAddModal} getUsers={getUsers} options={options} />
                  </UserPopup>
                </Dialog>

                <Stack direction="row" spacing={1.5}>
                  <Button variant="contained" onClick={handleAddModal} color="buttonone">
                    New Employee
                  </Button>
                </Stack>
              </Stack>
              <Card>
                <Stack direction="row" spacing={2} padding={2} justifyContent={'space-between'}>
                  <Stack direction="row" spacing={1.5}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      onClick={goToPage}
                      sx={{
                        backgroundColor: '#0096DB',
                        height: '40px',
                      }}
                    >
                      Go
                    </Button>
                  </Stack>
                  <Button
                    color="buttonone"
                    variant="contained"
                    onClick={() => setFilterOpen(!filterOpen)}
                    startIcon={<Iconify icon="ic:round-filter-list" />}
                  >
                    Filter
                  </Button>
                </Stack>
                <Filter applyFilters={applyFilters} open={filterOpen} setOpen={setFilterOpen} filterValues={options} />

                <Divider
                  sx={{
                    background: ' rgba(0, 0, 0, 0.50)',
                    marginBottom: '24px',
                  }}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                          <TableCell sx={commonHeadStyle}>Name</TableCell>
                          <TableCell sx={commonHeadStyle}>ID</TableCell>
                          <TableCell sx={commonHeadStyle}>Email</TableCell>
                          <TableCell sx={commonHeadStyle}>Role</TableCell>
                          <TableCell sx={commonHeadStyle}>Created At</TableCell>
                          <TableCell sx={commonHeadStyle}>Status</TableCell>
                          <TableCell sx={commonHeadStyle}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ ...commonBodyStyle, width: '60px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell
                              sx={{ ...commonBodyStyle, cursor: 'pointer' }}
                              onClick={() => navigate(`/dashboard/member/${item._id}`)}
                            >
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar alt={item.fullname} src={item.profilePic} />
                                <Typography sx={commonBodyStyle}>{item.fullname}</Typography>
                              </Stack>
                            </TableCell>
                            <CopyToClipboard text={item._id} onCopy={() => toast.info('user id copied')}>
                              <TableCell sx={{ ...commonBodyStyle, cursor: 'pointer' }}>
                                {item._id.slice(0, 6)}...
                              </TableCell>
                            </CopyToClipboard>
                            <TableCell sx={commonBodyStyle}>{item.email}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item.accType}</TableCell>
                            <TableCell sx={commonBodyStyle}>
                              {Moment.utc(item.createdAt).format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell sx={commonBodyStyle}>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                  size="small"
                                  sx={commonBodyStyle}
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem sx={commonBodyStyle} value="false">
                                    Active
                                  </MenuItem>
                                  <MenuItem sx={commonBodyStyle} value="true">
                                    Blocked
                                  </MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell align="center">
                              <Stack direction="row" spacing={1}>
                                <Box
                                  sx={{
                                    cursor: 'pointer',
                                    background: '#52D0B3',
                                    '&:hover': {
                                      background: '#42a78f',
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '7px',
                                    borderRadius: '10px',
                                  }}
                                  onClick={() => navigate(`/dashboard/member/${item._id}`)}
                                >
                                  <RemoveRedEyeRounded sx={{ color: '#fff', fontSize: '18px' }} />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={userCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  page={pages}
                  showFirstButton
                  showLastButton
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
