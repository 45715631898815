import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Collapse,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Iconify from './Iconify';

const headStyle = {
  color: '#0096DB',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const contentStyle = {
  color: '#3b3b3b',
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

const CommonListItem = ({ data, id }) => (
  <ListItem
    key={id}
    fullWidth
    sx={{
      borderRadius: '14.5px',
    }}
  >
    <ListItemAvatar>
      <Badge
        invisible={!data.isBlocked}
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
      >
        <Avatar alt="Remy Sharp" />
      </Badge>
    </ListItemAvatar>
    <ListItemText
      sx={{
        '& .MuiListItemText-primary': {
          ...contentStyle,
        },
      }}
      primary={data.fullname.charAt(0).toUpperCase() + data.fullname.slice(1)}
      secondary={data.email}
    />
  </ListItem>
);

const CommonStack = ({ type, handleClick, open }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ backgroundColor: '#fff', height: '60px', cursor: 'pointer', borderRadius: '14.5px' }}
    padding={2}
    onClick={handleClick}
  >
    <Typography sx={headStyle}>{type}</Typography>
    <Iconify
      icon="uiw:down-circle-o"
      color="#0096DB"
      width="24px"
      height="24px"
      sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }}
    />
  </Stack>
);

function MemberList({ members, manager }) {
  console.log('members', members);
  console.log('manager', manager);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={2} sx={{ minHeight: '454px' }}>
          <Stack spacing={1.5}>
            <CommonStack type="CLIENT" handleClick={handleClick} open={open} />
            <Collapse in={!open}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {members
                  .filter((item) => item.accType === 'CLIENT')
                  .map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
              </Stack>
            </Collapse>
          </Stack>

          {manager && (
            <Stack spacing={1.5}>
              <CommonStack type="MANAGER" handleClick={handleClick5} open={open5} />
              <Collapse in={!open5}>
                <Stack
                  spacing={1}
                  component="div"
                  disablePadding
                  sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                  mb={1}
                >
                  <CommonListItem data={manager} id={0} />
                </Stack>
              </Collapse>
            </Stack>
          )}

          {!manager && (
            <Stack spacing={1.5}>
              <CommonStack type="MANAGER" handleClick={handleClick5} open={open5} />
              <Collapse in={!open5}>
                <Stack
                  spacing={1}
                  component="div"
                  disablePadding
                  sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                  mb={1}
                >
                  {members
                    .filter((item) => item.role === 'MANAGER')
                    .map((mdata, id) => (
                      <CommonListItem key={id} data={mdata} id={id} />
                    ))}
                </Stack>
              </Collapse>
            </Stack>
          )}

          <Stack spacing={1.5}>
            <CommonStack type="PARTNERS" handleClick={handleClick1} open={open1} />
            <Collapse in={!open1}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {members
                  .filter((item) => item.accType === 'PARTNER')
                  .map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack spacing={1.5}>
            <CommonStack type="CONTRACTORS" handleClick={handleClick2} open={open2} />
            <Collapse in={!open2}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {members
                  .filter((item) => item.accType === 'CONTRACTOR')
                  .map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack spacing={1.5}>
            <CommonStack type="ARCHITECTS" handleClick={handleClick3} open={open3} />
            <Collapse in={!open3}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {members
                  .filter((item) => item.accType === 'ARCHITECT')
                  .map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack spacing={1.5}>
            <CommonStack type="EMPLOYEES" handleClick={handleClick4} open={open4} />
            <Collapse in={!open4}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {members
                  .filter((item) => item.accType === 'EMPLOYEE')
                  .map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default MemberList;
