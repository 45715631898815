import { useState } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, CircularProgress, Stack, TextField, Typography } from '@mui/material';
// utils
import { fNumber } from '../utils/formatNumber';
// components
import { BaseOptionChart } from '../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 100;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(0),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

PieChartCount.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function PieChartCount({ title, getNewData, graphloader, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [options, setOptions] = useState({ startDate, endDate });

  const chartLabels = chartData.map((i) => `${i._id.replaceAll('_', ' ').replace('RILL ', ' ')}:  ${i.total}`);

  const chartSeries = chartData.map((i) => i.total);

  const handleStartDateChange = (e) => {
    const temp = { ...options, startDate: e.target.value };
    setOptions(temp);
    getNewData(temp);
  };
  const handleEndDateChange = (e) => {
    const temp = { ...options, endDate: e.target.value };
    setOptions(temp);
    getNewData(temp);
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: {
      formatter: (val, opts) => {
        const value = opts.w.config.series[opts.seriesIndex];
        return `${value}`;
      },
      enabled: true,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => {
            const name = seriesName.split(':');
            return `${name[0]}:  `;
          },
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              formatter: (seriesName) => {
                const name = seriesName.split(':');
                return `${name[0]}`;
              },
            },
            value: {
              show: true,
              formatter: (seriesName) => {
                return `${seriesName}`;
              },
            },
            total: {
              show: true,
              formatter: (w) => {
                const total = w.config.series.reduce((partialSum, a) => partialSum + a, 0);
                return `${total}`;
              },
            },
          },
        },
        expandOnClick: false,
      },
    },
  });

  return (
    <Card {...other}>
      <Stack direction={'column'} alignItems="center" padding={1} sx={{ width: '100%' }}>
        <Typography marginBottom={1.5} marginTop={1} variant="h5">
          {title}
        </Typography>
        {/* <Stack
          direction={'row'}
          paddingX={1}
          spacing={1}
          alignItems="center"
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <TextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500,
                width: '100px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            id="margin-none"
            type="date"
            onChange={handleStartDateChange}
          />
          <TextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500,
                width: '100px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="End Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            type="date"
            onChange={handleEndDateChange}
          />
        </Stack> */}
      </Stack>
      <ChartWrapperStyle dir="ltr">
        {!graphloader && <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />}
        {graphloader && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </ChartWrapperStyle>
    </Card>
  );
}
