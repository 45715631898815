import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import {
  Container,
  Typography,
  MenuItem,
  Box,
  Button,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Card,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Stack,
  Grid,
  FormLabel,
  Autocomplete,
  CardContent,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { values } from 'lodash';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';
import BreadCrumps from '../components/BreadCrumps';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NewProject = () => {
  const [members, setMembers] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [taskLists, setTaskLists] = useState([]);
  const [isExist, setIsExist] = useState();
  const [categories, setCategories] = useState([]);
  // list members
  const navigate = useNavigate();
  const searchName = (e) => {
    const evalue = e.target.value.trim();
    if (!evalue) {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/exists`, {
        params: {
          name: evalue,
        },
      })
      .then((res) => {
        setIsExist(res.data.data.exist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMembers = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/member/all-sorted`).then((res) => {
        setMembers(res.data.data.members);
        console.log('All MEMBERSSSS', res.data.data.members);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/category`).then((res) => {
        setCategories(res.data.data.categories);
        console.log('All CATEGORIES', res.data.data.categories);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTaskLists = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/tasklist/list`).then((res) => {
        setTaskLists(res.data.data.tasks);
        console.log('tasks', res.data.data.tasks);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const callAPIs = async () => {
    await Promise.all[(getMembers(), getTaskLists(), getCategories())];
  };

  useEffect(() => {
    callAPIs();
  }, []);

  const ProjectSchema = Yup.object({
    name: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    description: Yup.string(),
    startDate: Yup.date(),
    endDate: Yup.date(),
    location: Yup.string().required('Location is required'),
    members: Yup.array(),
    manager: Yup.string().required('Manager is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      category: '',
      description: '',
      startDate: Moment.utc().format('YYYY-MM-DD'),
      endDate: '',
      location: '',
      members: [],
      manager: '',
      tasks: [],
    },
    validationSchema: ProjectSchema,
    onSubmit: async () => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('category', values.category);
      formData.append('description', values.description);
      formData.append('startDate', values.startDate);
      formData.append('endDate', values.endDate);
      formData.append('location', values.location);
      formData.append('manager', values.manager);
      formData.append('projectImage', values.projectImage);
      formData.append('members', JSON.stringify(values.members));
      formData.append('tasks', JSON.stringify(values.tasks));
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/project`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            toast.success(res.data.message);
            navigate(-1);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('tasks', checkedTasks);
  }, [checkedTasks]);

  const handleCheckBox = (event, key, checked) => {
    if (event.target.checked) {
      setCheckedTasks((current) => [...current, event.target.value]);
    } else {
      setCheckedTasks(checkedTasks.filter((i) => i !== event.target.value));
    }
  };

  useEffect(() => {
    console.log(checkedTasks);
  }, [checkedTasks]);

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Page title="Dashboard : new project">
        <Container maxWidth="xl">
          <Grid container spacing={3} rowGap={3}>
            <Grid item xs={12}>
              <BreadCrumps singlePage={'Create New Project'} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container justifyContent="center" xs={12}>
                    <FormikProvider value={formik}>
                      <Box width="100%">
                        <Form noValidate width="100%" onSubmit={handleSubmit}>
                          <Grid direction="row" rowSpacing={2} container xs={12}>
                            <Grid item spacing={2} xs={12}>
                              <Grid container justifyContent="flex-start" spacing={2} xs={12}>
                                <Grid item direction="row" xs={12} sm={12} md={7} lg={8}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    {/* <Typography> Name</Typography> */}
                                    <TextField
                                      label="Enter project name"
                                      name="name"
                                      type="text"
                                      fullWidth
                                      {...getFieldProps('name')}
                                      error={Boolean(touched.name && errors.name)}
                                      onKeyUp={searchName}
                                      helperText={
                                        (isExist === true && <p style={{ color: 'red' }}> Project already exist</p>) ||
                                        (isExist === false && <p style={{ color: 'green' }}>Project name available</p>)
                                      }
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={5} lg={4}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        multiple={false}
                                        id="multiple-checkbox"
                                        options={categories}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => {
                                          setFieldValue('category', value._id);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Category"
                                            onChange={handleChange}
                                            error={Boolean(touched.category && errors.category)}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Stack>
                                </Grid>

                                <Grid item xs={12} direction={'row'}>
                                  <Box sx={{ border: 'solid 0.5px #E8E8E8', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12} direction={'row'}>
                                      <Typography color={'gray'}>Select Task Categories</Typography>
                                    </Grid>
                                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                      {taskLists?.map((item, key) => (
                                        <FormControlLabel
                                          key={key}
                                          sx={{ marginRight: '20px' }}
                                          control={
                                            <Checkbox
                                              checked={checkedTasks.includes(item._id)}
                                              key={key}
                                              onChange={(e) => handleCheckBox(e, key, checked)}
                                              value={item._id}
                                            />
                                          }
                                          label={item.name}
                                        />
                                      ))}
                                    </FormGroup>
                                  </Box>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={6} lg={8}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    {/* <Typography>Location</Typography> */}
                                    <TextField
                                      fullWidth
                                      label="Enter location"
                                      name="location"
                                      type="text"
                                      {...getFieldProps('location')}
                                      error={Boolean(touched.location && errors.location)}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={3} lg={2}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    {/* <Typography>Start date</Typography> */}
                                    <TextField
                                      label="Start date"
                                      fullWidth
                                      defaultValue={Moment.utc().format('YYYY-MM-DD')}
                                      InputLabelProps={{ shrink: true }}
                                      name="startDate"
                                      type="date"
                                      {...getFieldProps('startDate')}
                                      error={Boolean(touched.startDate && errors.startDate)}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={3} lg={2}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    {/* <Typography>End date</Typography> */}
                                    <TextField
                                      label="End date"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      name="endDate"
                                      type="date"
                                      {...getFieldProps('endDate')}
                                      error={Boolean(touched.endDate && errors.endDate)}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={6} lg={4}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    <Box width="100%">
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          name="manager"
                                          fullWidth
                                          options={members}
                                          getOptionLabel={(option) => option.fullname}
                                          onChange={(e, value) => {
                                            setFieldValue('manager', value._id);
                                            console.log('value', value);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select manager"
                                              placeholder="Manager"
                                              onChange={handleChange}
                                              error={Boolean(touched.manager && errors.manager)}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12} sm={6} md={6} lg={4}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    <Box width="100%">
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          multiple
                                          id="multiple-limit-tags"
                                          name="members"
                                          options={members}
                                          getOptionLabel={(option) => option.fullname}
                                          onChange={(e, value) => {
                                            const temp = value.map((item, index) => item._id);
                                            setFieldValue('members', temp);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select members"
                                              placeholder="Members"
                                              onChange={handleChange}
                                              // {...getFieldProps('members')}
                                              error={Boolean(touched.members && errors.members)}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                  <TextField
                                    fullWidth
                                    type="file"
                                    InputLabelProps={{ shrink: true }}
                                    name="projectImage"
                                    label="Upload project image"
                                    onChange={(event) => {
                                      setFieldValue('projectImage', event.currentTarget.files[0]);
                                    }}
                                  />
                                </Grid>

                                <Grid item direction="row" xs={12} sm={12} md={12} lg={12}>
                                  <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                    {/* <Typography>Location</Typography> */}
                                    <TextField
                                      fullWidth
                                      label="Enter project description"
                                      name="description"
                                      type="text"
                                      {...getFieldProps('description')}
                                      error={Boolean(touched.description && errors.description)}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item direction="row" xs={12}>
                                  <Stack
                                    marginTop={1}
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                    direction="row"
                                  >
                                    <Button
                                      color="buttonone"
                                      className="btn btn-dark mt-3"
                                      variant="contained"
                                      size="small"
                                      type="submit"
                                    >
                                      Create
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      </Box>
                    </FormikProvider>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default NewProject;
