import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import BreadCrumps from '../components/BreadCrumps';
import Page from '../components/Page';

export const CreateTaskFromTask = () => {
  const [members, setMembers] = useState([]);
  const [projectCategory, setProjectCategory] = useState();
  const [subTasks, setSubTasks] = useState();
  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams);
  const category = searchParams.get('category');
  const id = searchParams.get('id');
  const getMembers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/members`, {
        params: { projectId: id },
      })
      .then((res) => {
        setMembers(
          res.data.data.members.filter((item) => {
            return item.isBlocked === false;
          })
        );
      });
  };

  const getOneList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/tasklist/view`, { params: { category } }).then((res) => {
      console.log('getOneList', res);
      setProjectCategory(res.data.data.tasks);
      setSubTasks(res.data.data.task.subTask);
      console.log('getdsf', res.data.data.task.subTask);
    });
  };
  // const subTasks = () => {
  //   projectCategory.subTask.map((pc) => pc);
  // };

  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log('projext', id);

  useEffect(() => {
    getMembers();
    getOneList();
  }, []);

  const TaskSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    // category: Yup.string().required('Category required'),
    description: Yup.string(),
    startDate: Yup.date(),
    endDate: Yup.date(),
    members: Yup.array(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectId: id,
      name: '',
      // category: '',
      description: '',
      startDate: '',
      endDate: '',
      members: '',
    },
    validationSchema: TaskSchema,
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/task`, { ...values, category })
          .then((res) => {
            toast.success(res.data.message);
            navigate(-1);
          })
          .catch((err) => {
            toast.error('something went wrong');
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  return (
    <Page title="Project : Add task">
      <Container>
        <Grid container spacing={3} rowGap={3}>
          <Grid item xs={12}>
            <BreadCrumps singlePage={'Create Task'} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Typography variant="h6">Category</Typography> */}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{category}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="category"
                              label="Select Category"
                              sx={{ width: '100%', height: '55px' }}
                              disabled
                            >
                              <MenuItem value={category}>{category}</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">Name</Typography>
                          <FormControl fullWidth sx={{ m: 1, minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-label">Name</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="name"
                              label="Select name"
                              sx={{ width: '100%', height: '55px' }}
                              {...getFieldProps('name')}
                              onChange={formik.handleChange}
                            >
                              {subTasks?.map((subTask, i) => (
                                <MenuItem key={i} value={subTask.name}>
                                  {subTask.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">Description</Typography>
                          <TextField
                            label="Enter description"
                            name="description"
                            type="text"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            fullWidth
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={5} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">Start date</Typography>
                          <TextField
                            label=""
                            name="startDate"
                            type="date"
                            {...getFieldProps('startDate')}
                            error={Boolean(touched.startDate && errors.startDate)}
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={5} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">End date</Typography>
                          <TextField
                            label=""
                            name="endDate"
                            type="date"
                            {...getFieldProps('endDate')}
                            error={Boolean(touched.endDate && errors.endDate)}
                          />
                        </Stack>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h6">Members</Typography>
                          <FormControl sx={{ m: 1, width: 300 }}>
                            <Autocomplete
                              multiple
                              id="multiple-limit-tags"
                              name="members"
                              options={members}
                              getOptionLabel={(option) => option.fullname}
                              onChange={(e, value) => {
                                const temp = value.map((item, index) => item._id);
                                setFieldValue('members', temp);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select members"
                                  placeholder="Members"
                                  onChange={handleChange}
                                  // {...getFieldProps('members')}
                                  error={Boolean(touched.members && errors.members)}
                                />
                              )}
                            />
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" marginTop={2} justifyContent="center" alignItems="center" spacing={2}>
                          <Button variant="contained" type="submit" color="buttonone">
                            Create
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
