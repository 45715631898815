import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import Iconify from './Iconify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopupComponent(props) {
  // const [open, setOpen] = React.useState(false);
  const {
    children,
    title,
    onClose,
    open,
    setOpen,
    openPopup,
    setIsOpen,
    setOpenModal,
    openModal,
    closePopup,
    ...other
  } = props;

  const handleClose = () => {
    closePopup();
  };

  return (
    <div  className='scrollCard'>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography textAlign={'center'} variant="h6" component="div">
          {title}
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
      <Divider />
    </div>
  );
}
