import { useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  TextField,
  MenuItem,
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 80;
const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar({ handleSearch }) {
  const [isOpen, setOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [accType, setAccType] = useState('');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    handleSearch(searchName, accType);
  };

  return (
    // <ClickAwayListener onClickAway={handleClose}>
    <div>
      {!isOpen && (
        <IconButton onClick={handleOpen}>
          <Iconify icon="eva:search-fill" width={20} height={20} />
        </IconButton>
      )}

      <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
        <SearchbarStyle>
          <Input
            autoFocus
            fullWidth
            disableUnderline
            placeholder="Search Members…"
            onChange={(e) => setSearchName(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleClose();
              }
            }}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
          />
          <TextField
            select
            autoFocus
            variant="standard"
            fullWidth
            size="small"
            label="Select Account Category"
            onChange={(e) => setAccType(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleClose();
              }
            }}
            sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
          >
            <MenuItem value="ARCHITECT">ARCHITECT</MenuItem>
            <MenuItem value="CLIENT">CLIENT</MenuItem>
            <MenuItem value="CONTRACTOR">CONTRACTOR</MenuItem>
            <MenuItem value="EMPLOYEE">EMPLOYEE</MenuItem>
            <MenuItem value="PARTNER">PARTNER</MenuItem>
          </TextField>
          <Button variant="contained" onClick={handleClose}>
            Search
          </Button>
        </SearchbarStyle>
      </Slide>
    </div>
    // </ClickAwayListener>
  );
}
